// ageCalculator.js

/**
 * Calculates the age based on a Firestore timestamp.
 * @param {Object} dob - The Firestore date of birth object with seconds and nanoseconds.
 * @returns {number} - The calculated age.
 */
export const calculateAge = (dob) => {
    if (!dob || !dob.seconds || typeof dob.seconds !== 'number') {
      throw new Error("Invalid date of birth object");
    }
  
    // Convert Firestore timestamp to a JavaScript Date object
    const birthDate = new Date(dob.seconds * 1000 + dob.nanoseconds / 1000000);
  
    // Get the current date
    const currentDate = new Date();
  
    // Calculate the difference in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();
  
    // Check if the birthday has occurred this year
    const hasHadBirthdayThisYear =
      currentDate.getMonth() > birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() >= birthDate.getDate());
  
    // If the birthday hasn't occurred yet this year, subtract 1 from the age
    if (!hasHadBirthdayThisYear) {
      age--;
    }
  
    return age;
  };