// Todo
// topics: politcal, technology, crime, sports, etc
// essentially just a search query, but made a preset

// dependencies
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button } from 'primereact/button'
import { ButtonGroup, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

// components

// lib
import { correctKeywords } from '../lib/keywordSpellchecker';
import { calculateAge } from '../lib/ageCalculator';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransactions2, setLedgerMode, setLedgerSettings } from '../redux/slices/bankSlice';


// Input options
import countryOptions from '../assets/countryOptions';

function openSearch() {
    // Expand the input width
    const searchInput = document.getElementById("searchInput");
    searchInput.style.width = "300px"; // Change to desired expanded width
    searchInput.style.marginRight = "2rem"; // Change to desired expanded width
    document.getElementById("myTopbar").style.height = "180px";
    const overlay = document.getElementById("overlay");
    overlay.style.display = "block"; // Show the overlay
}

function closeSearch() {
    // Collapse the input width
    const searchInput = document.getElementById("searchInput");
    searchInput.style.width = "150px"; // Change to desired default width
    searchInput.style.marginRight = "0"; // Change to desired expanded width
    document.getElementById("myTopbar").style.height = "0";
    const overlay = document.getElementById("overlay");
    overlay.style.display = "none"; // Hide the overlay
}

function LedgerSearch() {
    const dispatch = useDispatch(); //redux
    const { user, userData, userProfileData } = useSelector((state) => state.user);
    const { ledgerMode } = useSelector((state) => state.bank); //redux
    const { ledgerSettings } = useSelector((state) => state.bank); //redux

    // console.log(ledgerSettings)
    const changeMode = (mode) => {
        dispatch(setLedgerMode(mode)); // Set the news mode in Redux

        // Define the default settings based on the mode
        const defaultSettings = {
            recent: {
                ledgerSortType: 'created',
                ledgerSortBy: 'desc',
                ledgerQuantity: '25',
            },
            oldest: {
                ledgerSortType: 'created',
                ledgerSortBy: 'asc',
                ledgerQuantity: '25',
            },
            most: {
                ledgerSortType: 'amount',
                ledgerSortBy: 'desc',
                ledgerQuantity: '25',
            },
            least: {
                ledgerSortType: 'amount',
                ledgerSortBy: 'asc',
                ledgerQuantity: '25',
            },
        };

        const settings = defaultSettings[mode]; // Get the corresponding default settings for the selected mode
        closeSearch();
        dispatch(setLedgerSettings(settings)); // Dispatch the settings to Redux
    };

    // Handle setting changes dynamically
    const handleLedgerSettingsChange = (key, value) => {
        dispatch(setLedgerSettings({ [key]: value })); // Dispatch the updated setting (single key-value pair)
        dispatch(setLedgerMode(""));
    };


    // Search params
    const [keyword, setKeyword] = useState('');

    const handleSearch = async () => {
        closeSearch();
        // const correctedText = await correctKeywords(keyword);
        dispatch(fetchTransactions2({ keyword, ledgerSettings }));
        setKeyword('')
    };

    // Reference to the input field
    const inputRef = useRef(null);
    const handleKeyPress = (event) => {
        dispatch(setLedgerMode(""));
        if (event.key === 'Enter') {
            // Close the keyboard by blurring the input field
            inputRef.current.blur();
            handleSearch();
        }
    };

    // Create a loading component
    // Render loading state, error state, or data
    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;

    return (
        // SOCIAL SEARCH COMPONENT
        <div className='newsSearch d-flex me-4' >
            <FontAwesomeIcon size='lg' icon={faMagnifyingGlass} className='hover' onClick={openSearch} />
            <Container id='myTopbar' className='rounded'>
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <button className="closebtn mt-1 fs-2 p-0 bg-transparent" onClick={() => closeSearch()}>×</button>
                    <input ref={inputRef} onClick={openSearch} onKeyDown={handleKeyPress} id='searchInput' autoComplete="off" className='p-1 ps-3 mx-2 mt-2 round no-box-shadow' placeholder='Enter address to search' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                    <Button onClick={() => handleSearch()} className='newsSearch__button p-1 px-2 mt-2 fs-6 rounded border-0 text-white'>Go</Button>
                </div>
                <Row className='mt-3'>
                    {/* <p>Default Mode</p> */}
                    <Col>
                        <ButtonGroup className='justify-content-center w-100'>
                            <Button
                                onClick={() => changeMode('recent')}
                                active='recent'
                                className={`newsSearch__button rounded-start-custom border-end-0 p-1 px-2 fs-6 ${ledgerMode === 'recent' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Recent
                            </Button>
                            <Button
                                onClick={() => changeMode('oldest')}
                                active='oldest'
                                className={`newsSearch__button border-start-0 border-end-0 p-1 px-2 fs-6 ${ledgerMode === 'oldest' ? "bg-success text-white" : "bg-transparent"}`}
                            >
                                Oldest
                            </Button>
                            <Button
                                onClick={() => changeMode('most')}
                                active='most'
                                className={`newsSearch__button border-start-0 border-end-0 p-1 px-2 fs-6 ${ledgerMode === 'most' ? "bg-danger text-white" : "bg-transparent"}`}
                            >
                                Most
                            </Button>
                            <Button
                                onClick={() => changeMode('least')}
                                active='least'
                                className={`newsSearch__button rounded-end-custom border-start-0 p-1 px-2 fs-6 ${ledgerMode === 'least' ? "bg-danger text-white" : "bg-transparent"}`}
                            >
                                Least
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <hr className='m-2'></hr>
                {/* Date, Tone */}
                <Row className='mb-2'>
                    {/* <p>Default Mode</p> */}
                    <Col xs={5} sm={6}>
                        <ButtonGroup className='w-100'>
                            <Button
                                onClick={() => handleLedgerSettingsChange('ledgerSortType', 'created')}
                                active='Date'
                                className={`newsSearch__button rounded-start-custom border-end-0 p-1 px-2 fs-6 ${ledgerSettings.ledgerSortType === 'created' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Date
                            </Button>
                            <Button
                                onClick={() => handleLedgerSettingsChange('ledgerSortType', 'amount')}
                                active='Tone'
                                className={`newsSearch__button border-start-0 border-end-0 p-1 px-2 fs-6 ${ledgerMode === 'intense' ? "bg-danger text-white" : ledgerMode === 'casual' ? "bg-success text-white" : ledgerSettings.ledgerSortType === 'sentiment' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Amount
                            </Button>
                            <Button
                                onClick={() => handleLedgerSettingsChange('ledgerSortType', '')}
                                active=''
                                className={`newsSearch__button rounded-end-custom border-start-0 p-1 px-2 fs-6 ${ledgerSettings.ledgerSortType === '' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Any
                            </Button>
                        </ButtonGroup>
                    </Col>
                    {/* 
                    these modes set filters automatically
                    casual mode: DateAsc, 1d, 50, none, none
                    intense mode: ToneAsc, 1d?, 50, none, none 
                    */}
                    {/* Sort By: Asc, Desc, none  (Increasing / Decreasing)*/}
                    <Col>
                        <ButtonGroup className='justify-content-end w-100'>
                            <Button
                                onClick={() => handleLedgerSettingsChange('ledgerSortBy', 'asc')}
                                active='increasing'
                                className={`newsSearch__button rounded-start-custom border-end-0 p-1 px-2 fs-6 ${ledgerMode === 'intense' ? "bg-danger text-white" : ledgerSettings.ledgerSortBy === 'asc' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Ascend
                            </Button>
                            <Button
                                onClick={() => handleLedgerSettingsChange('ledgerSortBy', 'desc')}
                                active='Desc'
                                className={`newsSearch__button border-start-0 border-end-0 p-1 px-2 fs-6 ${ledgerMode === 'casual' ? "bg-success text-white" : ledgerSettings.ledgerSortBy === 'desc' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Descend
                            </Button>
                            <Button
                                onClick={() => handleLedgerSettingsChange('ledgerSortBy', '')}
                                active=''
                                className={`newsSearch__button rounded-end-custom border-start-0 p-1 px-2 fs-6 ${ledgerSettings.ledgerSortBy === '' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Any
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                {/* // Todo
                Date Range: 3 months total, calendar from to date, needs date in unix millisecond
                &startdatetime=20170322000000&enddatetime=20170401235959, and remove Time option
                 */}

                <Row>
                    {/* Time: 1h, 6h, 12h, 1-7d (spelled out for user) */}
                    {/* <p>Default Mode</p> */}
                    <Col>
                        <ButtonGroup className='justify-content-between w-100'>
                            {/* <select
                                name="time" id="time"
                                value={ledgerSettings.ledgerTime}
                                onChange={(e) => handleLedgerSettingsChange('ledgerTime', e.target.value)}
                                className='newsSearch__button p-sm-1 rounded bg-transparent'
                            >
                                <option value="1h">1 Hour</option>
                                <option value="6h">6 Hour</option>
                                <option value="12h">12 Hour</option>
                                <option value="1d">1 Day</option>
                                <option value="2d">2 Day</option>
                                <option value="3d">3 Day</option>
                                <option value="4d">4 Day</option>
                                <option value="5d">5 Day</option>
                                <option value="6d">6 Day</option>
                                <option value="7d">7 Day</option>
                                <option value="1m">1 Month</option>
                                <option value="3m">3 Months</option>
                                <option value="6m">6 Months</option>
                                <option value="1y">1 Year</option>
                            </select> */}

                            {/* NumOfRecords: 50, 100, 150, 200, 250 (Quantity: only when no results, 150-250)*/}
                            {/* <select
                                name="quantity" id="quantity"
                                value={ledgerSettings.ledgerQuantity}
                                onChange={(e) => handleLedgerSettingsChange('ledgerQuantity', e.target.value)}
                                className='newsSearch__button p-sm-1 rounded bg-transparent'
                            >
                                <option value="50">50 Transactions</option>
                                <option value="100">100 Transactions</option>
                                <option value="150">150 Transactions</option>
                                <option value="200">200 Transactions</option>
                                <option value="250">250 Transactions</option>
                            </select> */}

                            {/* Country: none or list of countries, lib for country code mapping, scrolling buttons from left to right custom component */}
                            {/* <select
                                name="country" id="country"
                                value={ledgerSettings.ledgerCountry}
                                onChange={(e) => handleLedgerSettingsChange('ledgerCountry', e.target.value)}
                                className='newsSearch__button p-sm-1 rounded bg-transparent'
                            >
                                {countryOptions.map((country, index) => (
                                    <option
                                        key={index}
                                        value={country.value}
                                    >
                                        {country.label}
                                    </option>
                                ))}
                            </select> */}
                        </ButtonGroup>
                    </Col>
                </Row>

                {/* Pagination to preserve load speed, load on scroll close by */}
                {/* close keyboard keydown in phone */}

            </Container>

            {/* Transparent overlay */}
            <div id="overlay" className="overlay" onClick={closeSearch}></div>
        </div >
    )
}

export default LedgerSearch