// ThemeSwitcher.js
import React, { useState, useEffect } from 'react';

const ThemeSwitcher = () => {
    const [theme, setTheme] = useState(() => {
        // Retrieve the theme from localStorage or default to 'light'
        return localStorage.getItem('theme') || 'dark';
    });

    useEffect(() => {
        // Save the current theme to localStorage whenever it changes
        localStorage.setItem('theme', theme);
    }, [theme]);

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    return (
        <div className='ThemeSwitcher text-start rounded d-flex align-items-center justify-content-center'>
            <p className='link' onClick={() => setTheme('light')}>Light</p>
            <p className='link' onClick={() => setTheme('dark')}>Dark</p>
            <p className='link' onClick={() => setTheme('blue')}>Blue</p>
        </div>
    );
};

export default ThemeSwitcher;