import React from 'react';

const TermsPrivacy = () => {
    return (
        <div className='TermsPrivacy__container' style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            {/* Summary Section */}
            <section className='summary'>
                <h1>Bluith Game Terms of Service and Privacy Policy</h1>
                <h2>Quick Summary</h2>
                <p>
                    Welcome to Bluith! By playing our game, you agree to our terms and conditions. We respect your privacy and are committed to protecting your personal information. Here's a brief overview:
                </p>
                <p><strong>Usage:</strong> You agree to comply with and be responsible for, all local laws of the location from which you access the Bluith network.</p>
                <p><strong>Data Collection:</strong> We collect minimal personal data necessary for gameplay.</p>
                <p><strong>Third-Party Services:</strong> We may use trusted third-party services for certain features.</p>
                <p><strong>Content Ownership:</strong> All game content is owned by Bluith Corp.</p>
                <p><strong>Conduct:</strong> Be respectful to others; no harassment, no bullying, no adult content, or other harmful behavior.</p>
                <p>
                    Please read the detailed terms below for complete information.
                </p>
            </section>

            {/* Detailed Terms of Service */}
            <section>
                <h2>Terms of Service</h2>
                <h3>1. Acceptance of Terms</h3>
                <p>
                    By accessing or using Bluith ("the Service"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the Service.
                </p>

                <h3 className='mt-3'>2. Eligibility</h3>
                <p>
                    You must be at least 14 years old to use the Service. Users under 18 years old require parental consent.
                </p>

                <h3 className='mt-3'>3. User Accounts</h3>
                <p>
                    You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                </p>

                <h3 className='mt-3'>4. License</h3>
                <p>
                    We grant you a non-exclusive, non-transferable, revocable license to use the Service for personal, non-commercial purposes.
                </p>

                <h3 className='mt-3'>5. Prohibited Conduct</h3>
                <p>You agree not to:</p>
                <ul>
                    <li>Use the Service for any illegal purpose.</li>
                    <li>Harass, threaten, or defraud other users.</li>
                    <li>Disrupt the Service or servers connected to the Service.</li>
                    <li>Attempt to gain unauthorized access to any part of the Service.</li>
                </ul>

                <h3 className='mt-3'>6. Intellectual Property</h3>
                <p>
                    All content, features, and functionality are owned by Bluith Corp. and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
                </p>

                <h3 className='mt-3'>7. Termination</h3>
                <p>
                    We reserve the right to terminate or suspend your access to the Service at our sole discretion, without notice, for conduct that we believe violates these Terms.
                </p>

                <h3 className='mt-3'>8. Disclaimer of Warranties</h3>
                <p>
                    The Service is provided "as is" without warranties of any kind, either express or implied.
                </p>

                <h3 className='mt-3'>9. Limitation of Liability</h3>
                <p>
                    In no event shall Bluith Corp. be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Service.
                </p>

                <h3 className='mt-3'>10. Governing Law</h3>
                <p>
                    These Terms shall be governed by and construed in accordance with the laws of your Country/State/Province/etc.
                </p>
            </section>

            {/* Privacy Policy */}
            <section className='mt-3'>
                <h2>Privacy Policy</h2>
                <h3>1. Information We Collect</h3>
                <p>
                    We collect information you provide directly to us, such as when you create an account or contact support. This may include your name, email address, and other contact information.
                </p>

                <h3 className='mt-3'>2. How We Use Your Information</h3>
                <p>
                    We use your information to operate, maintain, and improve the Service, respond to comments and questions, and provide customer service.
                </p>

                <h3 className='mt-3'>3. Cookies and Tracking Technologies</h3>
                <p>
                    We may use cookies and similar technologies to collect information about your use of the Service and to enhance your experience.
                </p>

                <h3 className='mt-3'>4. Third-Party Services</h3>
                <p>
                    We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis.
                </p>

                <h3 className='mt-3'>5. Data Security</h3>
                <p>
                    We implement reasonable security measures to protect your information but cannot guarantee its absolute security.
                </p>

                <h3 className='mt-3'>6. Children's Privacy</h3>
                <p>
                    We do not knowingly collect personal information from children under 14. If we become aware of such data, we will take steps to delete it.
                </p>

                <h3 className='mt-3'>7. Changes to This Policy</h3>
                <p>
                    We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated revision date.
                </p>

                <h3 className='mt-3'>8. Contact Us</h3>
                <p className='mb-5'>
                    If you have any questions about this Privacy Policy, please contact us at <a href="mailto:helpdesk@bluith.com">helpdesk@bluith.com.</a>
                </p>
            </section>
        </div>
    );
};

export default TermsPrivacy;