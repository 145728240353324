/**
 * Removes duplicate objects from an array based on the "title" property.
/**
 * Preprocesses an array of objects by removing duplicate "title" objects.
 * 
 * @param {Array} arrayOfObjects - The array of objects to preprocess.
 * @returns {Array|null} - A new array with duplicate "title" objects removed, or null if an error occurs.
 */
export function removeDuplicateArticles(arrayOfObjects) {
    // Check if the input is a valid array
    if (!Array.isArray(arrayOfObjects)) {
        console.error("Error: Input should be an array of objects.");
        return [];
    }

    const uniqueTitles = new Set();
    const filteredArray = [];

    arrayOfObjects.forEach((item, index) => {
        try {
            // Check if item has a valid title and if it's unique
            if (item && item.title && typeof item.title === 'string' && !uniqueTitles.has(item.title)) {
                uniqueTitles.add(item.title);
                filteredArray.push(item);
            }
        } catch (error) {
            // Log the error and continue processing the next item
            console.error(`Error processing item at index ${index}: ${error.message}`);
        }
    });

    return filteredArray;
}