// countryOptions.js
const countryOptions = [
    { value: "", label: "All Countries" },
    { value: "US", label: "United States" },
    { value: "CA", label: "Canada" },
    { value: "UK", label: "United Kingdom" },
    { value: "AS", label: "Australia" },
    { value: "FR", label: "France" },
    { value: "GM", label: "Germany" },
    { value: "CH", label: "China" },

    // ... Add more countries here
];

export default countryOptions;