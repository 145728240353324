import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


function ErrorPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/', { replace: true });
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer if the component is unmounted before the timeout
  }, [navigate]);

  return (
    <div className='errorPage bg-danger'>
      <p className='text-danger fw-bold fs-1 p-3 rounded bg-warning'>?</p>
      <h1 className='mb-5 text-warning'>Ohh No! That page does not exists</h1>
      <div className='fs-3 text-black'>
      <p>Redirecting to home in 3 seconds...</p>
      </div>
    </div>
  )
}

export default ErrorPage