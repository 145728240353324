import React, { useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

function QRCodeScanner({ onScan }) {

    useEffect(() => {
        const scanner = new Html5QrcodeScanner('qr-reader', { fps: 10, qrbox: 250 });
        
        scanner.render(
            (decodedText) => {
                console.log('QR code detected:', decodedText);
                onScan(decodedText);
            },
            (error) => {
                console.error('QR Scanner Error:', error);
            }
        );
        // Cleanup scanner on component unmount
        return () => {
            scanner.clear().catch((error) => console.error('Failed to clear QR code scanner', error));
        };
    }, [onScan]);

    return <div id="qr-reader" style={{ width: '100%' }} />;
}

export default QRCodeScanner;