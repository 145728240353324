/**
 * Formats a string of domain names separated by commas to the desired API format.
 * @param {string} domains - A string of domain names separated by commas.
 * @returns {string} - A formatted string for the API call.
 */
export const formatDomainsForApi = (domains) => {
    if (!domains) return '';
  
    // Split the domains by commas, trim any extra whitespace
    const domainArray = domains.split(',').map(domain => domain.trim());
  
    // Map each domain to the API format and join them with the 'OR' operator
    const formattedDomains = domainArray
      .map(domain => `domain:.${domain}%20OR%20domainis:${domain}`)
      .join('%20OR%20');
  
    return `(${formattedDomains})`;
  };
  
  // Example usage:
//   const input = "cnn.com, nytimes.com";
//   const formattedString = formatDomainsForApi(input);
//   console.log(formattedString);
  // Output: (domain:.cnn.com%20OR%20domainis:cnn.com%20OR%20domain:.nytimes.com%20OR%20domainis:nytimes.com%20OR%20domain:.bbc.co.uk%20OR%20domainis:bbc.co.uk)