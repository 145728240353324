import React from 'react';

const AudioPlayer = ({ audio }) => {
    return (
        <div>
            <audio controls className='py-2 w-100'>
                <source src={audio} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </div>
    );
};

export default AudioPlayer;