import axios from 'axios';

/**
 * Moderates the given text based on the current mod category.
 * @param {string} text - The text to be moderated.
 * @param {string} currentModCategory - The current moderation category.
 * @param {string} CHATGPT_API_KEY - The ChatGPT API key.
 * @param {string} CHATGPT_MODERATION_URL - The  ChatGPT API URL.
 * @returns {Promise<{ text: string, category: string }>} - Returns the text and its category (normal, unrated, flagged).
 */
export const moderateText = async (text, CHATGPT_MODERATION_URL, CHATGPT_API_KEY) => {
    try {
        // Step 1: Send text to ChatGPT moderation API
        const moderationResponse = await axios.post(
            CHATGPT_MODERATION_URL,
            { input: text },
            {
                headers: {
                    Authorization: `Bearer ${CHATGPT_API_KEY}`,
                },
            }
        );

        const moderationResult = moderationResponse.data.results[0];

        // Step 2: Define forbidden categories
        const forbiddenCategories = [
            'sexual/minors',
            'harassment/threatening',
            'hate/threatening',
            'self-harm/intent',
            'self-harm',
            'self-harm/instructions',
            // Add any additional forbidden categories here
        ];

        // Step 3: Handle flagged content
        if (moderationResult.flagged) {
            // Check if any forbidden category is present in the flagged result
            const isForbiddenContent = forbiddenCategories.some((category) => moderationResult.categories[category]);

            if (isForbiddenContent) {
                // Forbidden content found
                // alert('Illegal or Violating Content Detected. Unable to process post.');
                return { text, category: 'flagged' };
            } else {
                // Flagged content but not forbidden
                return { text, category: 'unrated' };
            }
        }

        // Step 4: If no content is flagged, return as normal content
        return { text, category: 'normal' };

    } catch (error) {
        console.error("Error moderating content: ", error);
        throw new Error('Error during moderation.');
    }
};