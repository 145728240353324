import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Input, Row } from 'reactstrap';
import PostCard from '../../components/PostCard';
import PostCreator from '../../components/PostCreator';

import { fetchPosts } from '../../redux/slices/socialSlice';

function SocialPage() {
    const dispatch = useDispatch();
    const { posts, socialSettings, socialMode, postAdded } = useSelector((state) => state.social);

    useEffect(() => {
        dispatch(fetchPosts({ socialSettings }));
    }, [socialMode, postAdded]);

    return (
        <Container fluid className="d-flex justify-content-center g-0">
            <div>
                <PostCreator />
                <div>
                    {posts?.map((post, index) => (
                        <PostCard key={index} post={post} />
                    ))}
                </div>
            </div>

        </Container>
    )
}

export default SocialPage