// timeFormatter.js

/**
 * Converts an ISO 8601 time string (like "20240902T014500Z") into a more readable format.
 * 
 * @param {string} isoTimeString - The ISO 8601 time string to be formatted.
 * @returns {string|null} - A human-readable date and time string, or null if an error occurs.
 */
function formatISOTime(isoTimeString) {
    try {
        // Validate the input
        if (!isoTimeString || typeof isoTimeString !== 'string' || isoTimeString.length < 15) {
            return isoTimeString;
        }

        // Insert the correct separators to convert the input to a valid ISO 8601 format
        const year = isoTimeString.substring(0, 4);
        const month = isoTimeString.substring(4, 6);
        const day = isoTimeString.substring(6, 8);
        const hour = isoTimeString.substring(9, 11);
        const minute = isoTimeString.substring(11, 13);
        const second = isoTimeString.substring(13, 15) || '00'; // Optional seconds

        // Combine into a valid ISO 8601 format
        const formattedIsoString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;

        // Create a Date object
        const date = new Date(formattedIsoString);

        if (isNaN(date.getTime())) {
            return isoTimeString;
        }

        // Format the date into the desired custom format "MM/DD/YY HHMM UTC"
        const formattedDate = `${String(date.getUTCMonth() + 1).padStart(2, '0')}/${String(date.getUTCDate()).padStart(2, '0')}/${String(date.getUTCFullYear()).slice(2)} ${String(date.getUTCHours()).padStart(2, '0')}${String(date.getUTCMinutes()).padStart(2, '0')} UTC`;

        return formattedDate;
    } catch (error) {
        // Log the error and return null
        console.error(`Error formatting ISO time string: ${error.message}`);
        return null;
    }
}

// Export the function to be used in other files
module.exports = { formatISOTime };