import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearError, sendMessage } from '../../redux/slices/chatSlice';
import EmojiPicker from 'emoji-picker-react'; // Emoji picker for sending emojis
import { collection, query, orderBy, onSnapshot, limit } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Your Firebase configuration file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function PublicChat() {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.user); //redux
    const { error } = useSelector((state) => state.chat); //redux

    // State for the selected chat type
    const [message, setMessage] = useState('');
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    const [chatMessages, setChatMessages] = useState([]); // Local state for messages

    useEffect(() => {
        const chatCollection = collection(firestore, 'public_chats');
        const q = query(chatCollection, orderBy('created', 'desc'), limit(25));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const loadedMessages = [];
            snapshot.forEach((doc) => {
                const data = doc.data();
                loadedMessages.push({ ...data, message: data.message });
            });
            setChatMessages(loadedMessages);
        });

        return () => {
            // Clean up the listener when the component unmounts or chat type changes
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        const lastMessage = document.querySelector('.chat-messages > .message:first-child');
        if (lastMessage) {
            lastMessage.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatMessages]);

    const handleSendMessage = async () => {
        if (!message) return;

        dispatch(sendMessage({
            message,
            senderId: userData['@name'],
            profileImageUrl: userData.profile_photo_url,
        }));

        setMessage('');
        setEmojiPickerOpen(false);
    };

    const handleInputChange = (e) => {
        setMessage(e.target.value);

        // Clear the error when the user starts typing
        if (error) {
            dispatch(clearError());
        }
    };

    const handleEmojiSelect = (emoji) => {
        setMessage((prev) => prev + emoji.emoji);
    };

    return (
        <div className='chat-container'>
            {/* Main chat window */}
            <div className="chat-window">
                <div className="chat-messages">
                    {chatMessages.map((msg, index) => (
                        <div key={index} className="message">
                            <p><img src={msg.profile_photo_url} alt="Attached" width='25' className='round' /> {msg.message}</p>
                        </div>
                    ))}
                </div>

                {/* Chat input area */}
                <div className='emoji-picker'>
                    {emojiPickerOpen && <EmojiPicker onEmojiClick={handleEmojiSelect} />}
                </div>
                <div className="chat-input blur-background p-1">
                    {error && <div className="PublicChat__errorMessage text-danger bg-warning fw-bold position-absolute rounded">{error}</div>}
                    {/* Error message */}
                    <input
                        value={message}
                        onChange={handleInputChange}
                        placeholder="Type a message..."
                        className='rounded'
                    />
                    <button className='emoji-picker-button bg-transparent p-1' onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}>😀</button>
                    <button className='button p-1 px-3 ms-2 bg-primary text-white rounded' disabled={!message} onClick={handleSendMessage}><FontAwesomeIcon icon={faPaperPlane} /></button>
                </div>
            </div>
        </div>
    )
}

export default PublicChat