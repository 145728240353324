import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Globe3D from '../components/Globe';

const cities = [
  { name: 'Washington D.C.', offset: -4 },
  { name: 'New York', offset: -4 }, // UTC -4 (for Eastern Daylight Time)
  { name: 'London (UTC)', offset: 0 }, // UTC +0 (for Greenwich Mean Time)
  { name: 'Berlin', offset: 2 }, // UTC +2 (for Central European Summer Time)
  { name: 'Moscow', offset: 3 }, // UTC +3
  { name: 'Dubai', offset: 4 }, // UTC +4
  { name: 'Beijing', offset: 8 }, // UTC +8
  { name: 'Tokyo', offset: 9 }, // UTC +9
];

function HomePage() {
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update the time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clear interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  // Function to get time for each city
  const getCityTime = (offset) => {
    // Get the local time in UTC (milliseconds) + convert to hours + the city's offset
    const utc = currentTime.getTime() + currentTime.getTimezoneOffset() * 60000;
    const cityTime = new Date(utc + 3600000 * offset);

    // Format the time to display hours, minutes, and seconds
    return cityTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  };

  return (
    <Container fluid className='HomePage g-0'>
      <Row className='world-clock-container w-100 justify-content-between' style={{ height: '6vh'}}>
        {cities.map((city) => (
          <Col className='text-center city-container g-0' key={city.name}>
            <p className='fw-bold city-name'>{city.name}</p>
            <p className='city-time'>{getCityTime(city.offset)}</p>
          </Col>
        ))}
      </Row>
      <Row className='text-center'>
        <Col lg={2} className='border border-1 border-secondary'>
          <p>Weather</p>
        </Col>
        <Col lg={2} className='border-top border-bottom border-secondary'>
          <p>Social</p>
        </Col>
        <Col lg={4} className='HomePage__GeoWatch border p-0 border-1 border-secondary' style={{ height: '28rem'}}>
          <p>GeoWatch</p>
          <Globe3D />
        </Col>
        <Col lg={2} className='border-top border-bottom border-secondary'>
          <p>News</p>
        </Col>
        <Col className='border border-1 border-secondary'>
          <p>Sports</p>
        </Col>
      </Row>
      <Row className='text-center' style={{ height: '26rem'}}>
        <Col lg={2} className='border-end border-secondary'>
          <p>Artifacts</p>
        </Col>
        <Col lg={2} className=''>
          <p>Stocks</p>
        </Col>
        <Col lg={2} className='border-start border-secondary'>
          <p>Bank</p>
        </Col>
        <Col lg={2} className='border-end border-start border-secondary'>
          <p>Safety</p>
        </Col>
        <Col lg={2} className=''>
          <p>Government</p>
        </Col>
        <Col className='border-start border-secondary'>
          <p>Messages</p>
        </Col>
      </Row>
    </Container>
  );
}

export default HomePage;