// transactionProcessor.js

import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase'; // Adjust the path to your firestore instance

/**
 * Processes transactions to replace encrypted_uids with corresponding @names.
 * 
 * @param {Array} transactionsArray - The array of transaction objects to process.
 * @returns {Array} - The modified array with @name replacements, sorted by creation time, and limited to 5.
 */
export async function updateAndReplaceEncryptedUids(transactionsArray, limitBy = 5) {
    if (!Array.isArray(transactionsArray)) {
        console.error("Input should be an array of transaction objects.");
        return transactionsArray;
    }

    // Extract unique encrypted_uids from transactions
    const uniqueEncryptedUids = [
        ...new Set(transactionsArray.flatMap((t) => [t.receiver, t.sender]))
    ];

    // Filter out any invalid encrypted_uid values
    const validUids = uniqueEncryptedUids.filter(uid => typeof uid === 'string' && uid.trim() !== '');

    if (validUids.length === 0) {
        return transactionsArray; // If no valid uids, return original transactions
    }

    // Fetch the mapping of encrypted_uids to @names
    const uidsToNamesMap = await fetchEncryptedUids(validUids);

    // Replace encrypted_uids in transactions with corresponding @names
    const transactionsWithNames = transactionsArray.map((transaction) => {
        // Create a copy of the transaction to avoid mutation
        let newTransaction = { ...transaction };

        // Replace receiver and sender encrypted_uids with @names
        newTransaction.receiver = uidsToNamesMap[newTransaction.receiver] || newTransaction.receiver;
        newTransaction.sender = uidsToNamesMap[newTransaction.sender] || newTransaction.sender;

        return newTransaction;
    });

    // Sort transactions by creation time and limit to last 5
    return transactionsWithNames
        .sort((a, b) => b.created.toMillis() - a.created.toMillis())
        .slice(0, limitBy);
}

/**
 * Fetches @names corresponding to an array of encrypted_uids from Firestore.
 * 
 * @param {Array} uids - The array of unique encrypted_uids to fetch.
 * @returns {Object} - A map of encrypted_uids to their corresponding @names.
 */
async function fetchEncryptedUids(uids) {
    const uidsToNamesMap = {};

    try {
        // Query Firestore for all provided uids
        const uidsQuery = query(
            collection(firestore, 'user_@name'),
            where('encrypted_uid', 'in', uids)
        );

        const querySnapshot = await getDocs(uidsQuery);

        // Map the results to a uid-to-name object
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            uidsToNamesMap[data.encrypted_uid] = data['@name'];
        });

    } catch (error) {
        console.error("Error fetching encrypted UIDs:", error);
    }

    return uidsToNamesMap;
}