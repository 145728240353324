import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { cleanAndReformatTitles } from '../../lib/dataCleaner';
import { formatDomainsForApi } from '../../lib/formatDomainsForApi';
import translateText from '../../lib/translatorText';

// Google Translate API URL
const GOOGLE_TRANSLATE_URL = 'https://translation.googleapis.com/language/translate/v2';
const GOOGLE_TRANSLATE_API_KEY = 'AIzaSyBczKTFqESInEK1OMnoRdkEXz1-AtYaGlk'

// Async thunk to fetch news data from search params
export const fetchNews = createAsyncThunk(
  'news/fetchNews',
  async ({ correctedText, newsSettings: { newsSortType, newsSortBy, newsCountry, newsDomain, newsQuantity, newsTime } }) => {
    const { translatedText, detectedLanguage } = await translateText(correctedText, GOOGLE_TRANSLATE_API_KEY, GOOGLE_TRANSLATE_URL);
    const formattedDomains = formatDomainsForApi(newsDomain)
    const apiUrl = `https://api.gdeltproject.org/api/v2/doc/doc?query=${translatedText && 'near5:"' + translatedText + '"'}%20${formattedDomains && "domainis:" + formattedDomains}%20${newsCountry && 'sourcecountry:' + newsCountry}&mode=ArtList&maxrecords=${newsQuantity}&trans=googtrans&sort=${newsSortType + newsSortBy}&format=json&timespan=${newsTime}`
    // console.log(apiUrl)
    const response = await axios.get(apiUrl);
    console.log("running: fetch searched news")
    const result = await cleanAndReformatTitles(response.data.articles);
    return { articles: result };
  }
);

// Async thunk to fetch news data
export const fetchTrendingNews = createAsyncThunk(
  'news/fetchTrendingNews',
  async ({ sortingMode }) => {
    // trending news updates Q15min, update to pull based on users language, or location, regions something
    const apiUrl = `https://api.gdeltproject.org/api/v2/doc/doc?query=(near5:%22world%22%20OR%20near5:%22global%22)&mode=ArtList&maxrecords=50&trans=googtrans&sort=${sortingMode}&format=json&timespan=6h`;
    // console.log("running: fetch trending news")
    const response = await axios.get(apiUrl);
    console.log("running: fetch default news")
    const result = await cleanAndReformatTitles(response.data.articles);
    return { articles: result };
  }
);

const newsSlice = createSlice({
  name: 'news',
  initialState: {
    newsData: [],
    newsMode: 'recent',
    newsSettings: {
      newsSortType: 'Date',
      newsSortBy: 'Asc',
      newsTime: '1d',
      newsQuantity: '50',
      newsCountry: '',
      newsDomain: '',
    },
    status: 'idle',
    error: null,
  },
  reducers: {
    setNewsMode: (state, action) => {
      state.newsMode = action.payload;
    },
    // Action to update news settings (single or multiple)
    setNewsSettings: (state, action) => {
      state.newsSettings = {
        ...state.newsSettings, // Keep the existing settings intact
        ...action.payload, // Merge the new settings into the state
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.newsData = action.payload;
      })
      .addCase(fetchTrendingNews.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.newsData = action.payload;
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// Action creators are generated for each case reducer function
export const { setNewsMode, setNewsSettings } = newsSlice.actions

export default newsSlice.reducer;