/**
 * Formats a given Unix timestamp as a relative "time ago" string.
 *
 * @param {number} unixSeconds - Unix timestamp in seconds (e.g., 1631579886).
 * @returns {string} - The formatted relative time string (e.g., "2 days ago", "just now").
 */
export function timeAgo(unixSeconds) {
    // Validate the input
    if (!Number.isFinite(unixSeconds)) {
        console.error('Invalid unixSeconds:', unixSeconds);
        return 'Invalid time';
    }

    const date = new Date(unixSeconds * 1000); // Convert seconds to milliseconds
    if (isNaN(date.getTime())) {
        console.error('Invalid date object:', date);
        return 'Invalid date';
    }

    const now = new Date();
    const secondsAgo = Math.floor((now - date) / 1000);
    if (!Number.isFinite(secondsAgo)) {
        console.error('Invalid secondsAgo:', secondsAgo);
        return 'Invalid time difference';
    }

    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
    };

    for (const [unit, value] of Object.entries(intervals)) {
        const count = Math.floor(secondsAgo / value);
        if (count !== 0 && Number.isFinite(count)) {
            return new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(-count, unit);
        }
    }

    return 'just now';
}