/**
 * Preprocessor function to parse an array of objects, clean unexpected tokens,
 * and reformat the title field by removing spaces around punctuation.
 *
 * @param {Array} dataArray - The array of objects to preprocess.
 * @returns {Array} - A new array with cleaned and reformatted title fields.
 */
export function cleanAndReformatTitles(dataArray) {
    if (!Array.isArray(dataArray)) {
        console.error("Input should be an array of objects.");
        return [];
    }

    // Regex to match and clean unexpected tokens
    const unexpectedTokenRegex = /\\/g;

    // Regex to remove spaces around specific punctuation in the title field
    const punctuationSpaceRegex = /\s*([,.\-?!|:;$)(&@“‘])\s*/g;

    return dataArray.map(item => {
        const cleanedItem = { ...item }; // Shallow copy to avoid mutation

        if (typeof item.title === 'string') {
            // Step 1: Replace unexpected tokens in the title
            let cleanedTitle = item.title.replace(unexpectedTokenRegex, '');

            // Step 2: Reformat spaces around punctuation only for the title
            cleanedTitle = cleanedTitle.replace(punctuationSpaceRegex, '$1');

            cleanedItem.title = cleanedTitle;
        }

        return cleanedItem;
    });
}