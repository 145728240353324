import React from 'react'

import CountdownTimer from '../components/CountdownTimer';
import washington from '../assets/file.png'
import weThePeopleImage from '../assets/wethepeople2.png'
import sumCoin from '../assets/summation_symbol_tbg.png'
import nefertiti from '../assets/nefertiti2.png'
import mapIcon from '../assets/mapIcon.jpeg'
import economyIcon from '../assets/economyIcon.jpeg'
import governmentIcon from '../assets/governmentIcon.jpeg'
import tradingIcon from '../assets/tradingIcon.jpeg'
import casinoIcon from '../assets/casinoIcon.jpeg'
import casinoIconTBG from '../assets/gamescasino_tbg.png'
import AInewsIcon from '../assets/AInewsIcon.jpeg'
import AInewsTBG from '../assets/AInewsmap_tbg.png'
import earthTBG from '../assets/earth_tbg2.png'
import chatTBG from '../assets/chatIcon2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import JoinNowModal from '../components/JoinNowModal';

function LandingPage() {
  return (
    <div className="LandingPage__container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content p-3 round">
          <h1 className="hero-title">A Real-World Game</h1>
          <p className="hero-subtitle">Elect leaders, trade goods, and explore real-world opportunities.</p>
          <JoinNowModal getStartedButton/>
          {/* <button className="cta-button fw-bold bg-white ms-3">Learn more</button> */}
        </div>
        <div className="hero-background">
          {/* Background image for the hero section */}
          <img src={washington} alt="World map" className="background-image mx-auto" />
        </div>
      </section>

      {/* subsection */}
      <section className="subsection bg-black">
        <div className="hero-content p-3 round">
          <h1 className="hero-title">Democratic Government</h1>
          <p className="hero-subtitle">Vote for real players. Effect real change. Pass/propose laws.</p>
        </div>
        <div className="hero-background">
          {/* Background image for the hero section */}
          <img src={weThePeopleImage} alt="World map" className="background-image mx-auto" />
        </div>
      </section>
      <section className="subsection">
        <div className="hero-content p-3 round">
          <h1 className="hero-title">Limited In-Game Currency</h1>
          <p className="hero-subtitle">Pay friends. In-game jobs. Bounties. Markets. Collect on the go.</p>
        </div>
        <div className="hero-background">
          {/* Background image for the hero section */}
          <img src={sumCoin} alt="World map" className="background-image mx-auto" />
        </div>
      </section>
      <section className="subsection bg-black">
        <div className="hero-content p-3 round">
          <h1 className="hero-title">Find Artifacts</h1>
          <p className="hero-subtitle">Collect real-world objects. Nefertiti's Bust. Rare as the real world. Trade with other players.</p>
        </div>
        <div className="hero-background">
          {/* Background image for the hero section */}
          <img src={nefertiti} alt="World map" className="background-image mx-auto" />
        </div>
      </section>
      <section className="subsection">
        <div className="hero-content p-3 round">
          <h1 className="hero-title">Games</h1>
          <p className="hero-subtitle">Casino. Lottery. Betting. BlackJack.</p>
        </div>
        <div className="hero-background">
          {/* Background image for the hero section */}
          <img src={casinoIconTBG} alt="World map" className="background-image mx-auto" />
        </div>
      </section>
      <section className="subsection bg-black">
        <div className="hero-content p-3 round">
          <h1 className="hero-title">Game Map</h1>
          <p className="hero-subtitle">Geo events. Found Artifacts. Disasters. Safety alerts.</p>
        </div>
        <div className="hero-background">
          {/* Background image for the hero section */}
          <img src={earthTBG} alt="World map" className="background-image mx-auto" />
        </div>
      </section>
      <section className="subsection">
        <div className="hero-content p-3 round">
          <h1 className="hero-title">World News</h1>
          <p className="hero-subtitle">AI Summaries. Stay informed. Worldwide. Multilingual origins.</p>
        </div>
        <div className="hero-background">
          {/* Background image for the hero section */}
          <img src={AInewsTBG} alt="World map" className="background-image mx-auto" />
        </div>
      </section>
      <section className="subsection bg-black">
        <div className="hero-content p-3 round">
          <h1 className="hero-title">Connect with Friends</h1>
          <p className="hero-subtitle">Public feed. Chat. Gatherings. Share Finds.</p>
        </div>
        <div className="hero-background">
          {/* Background image for the hero section */}
          <img src={chatTBG} alt="World map" className="background-image mx-auto" />
        </div>
      </section>
      {/* Features Section */}
      {/* <section className="features-section">
        <div className="features-grid">
          <div className="feature-item">
            <img src={governmentIcon} alt="Governance" />
            <h3>Governance</h3>
            <p>Elect leaders, pass laws, vote.</p>
          </div>
          <div className="feature-item">
            <img src={economyIcon} alt="Economy" />
            <h3>Economy</h3>
            <p>Blockchain-secured, market, jobs.</p>
          </div>
          <div className="feature-item">
            <img src={tradingIcon} alt="Trading" />
            <h3>Trading</h3>
            <p>Stocks, crypto, artifacts.</p>
          </div>
          <div className="feature-item">
            <img src={casinoIcon} alt="Betting" />
            <h3>Games</h3>
            <p>Lottery, bounties, betting.</p>
          </div>
          <div className="feature-item">
            <img src={AInewsIcon} alt="AI News" />
            <h3>News</h3>
            <p>AI world news summaries.</p>
          </div>
          <div className="feature-item">
            <img src={mapIcon} alt="Chat & Map" />
            <h3>Chat & Map</h3>
            <p>Connect, GPS events, collectables.</p>
          </div>
        </div>
      </section> */}

      {/* Call to Action Section */}
      <section className="cta-section">
        <h2>Start Playing Today</h2>
        <div className='d-flex mx-auto justify-content-center align-items-center'>
          <JoinNowModal getStartedButton />
        </div>
          <Link to='/manual' className="cta-button fw-bold bg-white mx-auto">Learn more</Link>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="social-icons mt-1">
          <a href="https://x.com/bluith_" target="_blank" rel="noopener noreferrer">
            <span className="x-logo ms-3 m-2" style={{ width: '25px', height: '25px' }} />
          </a>
          <a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=100094219868770" target="_blank" rel="noopener noreferrer">
            <span className="facebook-logo m-2" style={{ width: '31px', height: '31px' }} />
          </a>
        </div>
        <div className='ProfilePage__footer d-md-flex justify-content-center align-items-center text-center'>
          <Link to='/terms-privacy' className='ms-md-2 order-md-1'>Terms & Privacy</Link>
          <p>Copyright © 2024 Bluith Corp. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}

export default LandingPage