import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { firestore, auth } from '../../firebase';  // Import Firestore instance
import { doc, setDoc, serverTimestamp, getDoc, collection, query, where, getDocs, addDoc, orderBy, limit } from 'firebase/firestore';
import { createBankAccount } from './bankSlice'; // Import the bank account creation thunk
import crypto from 'crypto-js';

// Thunk action to create user and user profile in Firestore
export const createUser = createAsyncThunk(
    'user/createUser',
    async (authData, { rejectWithValue, dispatch }) => {
        try {
            const { uid, email, photoURL, displayName } = authData.user;

            // Step 1: Create the user document in the 'users' collection
            const userDocRef = doc(firestore, 'users', uid);  // 'uid' is used as the document ID
            const userDoc = await getDoc(userDocRef);


            // If user already exists, stop the signup process and return a message to login
            if (userDoc.exists()) {
                console.log("User already exists, stopping signup process.");
                return rejectWithValue('User already exists. Please log in instead.');
            }

            // Step 2: Generate a unique @name
            const generatedName = await generateUniqueName(email);

            // Step 3: Encrypt the UID
            const encryptedUid = hashValue(uid);

            const userData = {
                user_uid: uid,
                encrypted_uid: encryptedUid, // Store the encrypted UID
                email: email,
                profile_photo_url: photoURL,
                signup_date: serverTimestamp(),  // Firebase timestamp for signup date
                '@name': generatedName,  // Autogenerated unique @name
                display_name: displayName || 'Anonymous',
            };

            // Save user document in Firestore
            await setDoc(userDocRef, userData);

            // Step 3: Create the user profile document in 'user_profiles' collection in the background
            const userProfileDocRef = doc(firestore, 'user_profiles', uid);
            const userProfileData = {
                user_uid: uid,
                networth: 0,  // Set default networth to 0
                member_type: 'FREE',  // Default membership type
                permissions: {
                    intense: false,  // Default permission for intense content
                },
                preferences: {
                    language: 'English',  // Default language from browser
                    notifications: true,  // Default notifications setting
                    geowatch: true,  // Enable geowatch for artifact collection
                    privacySettings: {
                        visibility: 'public',  // Default to public visibility
                        searchable: true,  // Allow searchability by default
                    },
                },
                account_status: 'active',  // Default account status
                bio: 'Just a regular person!',
                birthdate: '',  // Will be updated later
                location: '',  // Can be pulled from GPS/browser settings later
                gender: '',  // Optional field for later
                contacts: [],  // Empty contacts by default
            };

            // Save user profile document in Firestore
            await setDoc(userProfileDocRef, userProfileData);

            // Step 5: Create the user_@name document in 'user_@name' collection
            const userAtNameDoc = {
                '@name': generatedName,
                encrypted_uid: encryptedUid,
            };

            // Reference to the 'user_@name' collection and save the document with an autogenerated ID
            const userAtNameCollectionRef = collection(firestore, 'user_@name');
            await addDoc(userAtNameCollectionRef, userAtNameDoc);

            // Step 4: Create a default bank account for the user
            await dispatch(createBankAccount({
                encrypted_uid: encryptedUid, // Store the encrypted UID
                accountType: 'checking', // or any default account type you want to create
            }));

            return { user_uid: uid, userData, userProfileData };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Function to hash the input
const hashValue = (value) => {
    return crypto.SHA256(value).toString(crypto.enc.Hex);
};

// Helper function to generate a unique @name
const generateUniqueName = async (email) => {
    if (!email || typeof email !== 'string' || !email.includes('@')) {
        throw new Error("Invalid email provided"); // Handle the error appropriately
    }
    
    // Extract the part of the email before the '@' symbol
    const baseName = email
        .split('@')[0] // Get part before '@'
        .toLowerCase() // Convert to lowercase
        .replace(/[^a-z0-9]/g, ''); // Remove non-alphanumeric characters

    let uniqueName = baseName;
    let suffix = 0;

    // Check if the baseName exists in Firestore and keep incrementing suffix until a unique name is found
    while (!(await checkIfNameIsAvailable(uniqueName))) {
        suffix += 1;
        uniqueName = `${baseName}${suffix}`; // Append numbers to make name unique
    }

    return uniqueName;
};

// Helper function to check if @name is available
const checkIfNameIsAvailable = async (userName) => {
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, where('@name', '==', userName));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;  // Returns true if name is available
};


// Thunk action to sign in a user and store their data in Redux
// if user doc does not exist
export const signinUser = createAsyncThunk(
    'user/signinUser',
    async (uid, { rejectWithValue }) => {
        try {
            // Fetch the user document from Firestore using the UID
            const userDocRef = doc(firestore, 'users', uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                // If the user document exists, return the data
                return userDoc.data();  // This will be passed to the fulfilled reducer
            } else {
                // No user exists with this UID or email, allow sign up or throw an error
                throw new Error('No account found. Signing out.');
            }
        } catch (error) {
            return rejectWithValue(error.message);  // This will be passed to the rejected reducer
        }
    }
);

// Async thunk to fetch or handle no user profile found
export const fetchUserProfile = createAsyncThunk(
    'user/fetchUserProfile',
    async (_, { rejectWithValue }) => {
        try {
            // Get the current authenticated user UID
            const user = auth.currentUser;
            if (!user) {
                throw new Error('No authenticated user');
            }

            const userUid = user.uid;

            // Fetch the user document from Firestore using the UID
            const userDocRef = doc(firestore, 'user_profiles', userUid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                // If the user document exists, return the data
                return userDoc.data();
            } else {
                // No user profile exists, handle the error or initiate profile creation if needed
                throw new Error('No user profile found. Please create a profile.');
            }
        } catch (error) {
            // Return the error message to be handled by the rejected reducer
            return rejectWithValue(error.message);
        }
    }
);

// Thunk to submit ticket
export const submitTicket = createAsyncThunk(
    'tickets/submit',
    async ({ message, email, name, username }) => {
        try {
            // Add ticket to Firestore
            await addDoc(collection(firestore, 'tickets'), {
                message,
                email,
                name,
                username,
                createdAt: serverTimestamp(),
            });
            alert("We'll get back to you in 24-48 hours.")
        } catch (error) {
            console.error('Error submitting ticket: ', error);
        }
    }
);

// Fetch tickets by user's @name
export const fetchUserTickets = createAsyncThunk(
    'tickets/fetchByAtName',
    async (username) => {
        try {
            const ticketsRef = collection(firestore, 'tickets');
            const q = query(
                ticketsRef,
                where('username', '==', username),
                orderBy('createdAt', 'desc'),
                limit(5)
            );
            const querySnapshot = await getDocs(q);

            // Extract ticket data
            const tickets = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            return tickets;
        } catch (error) {
            console.error('Error fetching tickets: ', error);
            throw error;
        }
    }
);

// change the state based on the called function
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        userData: null,
        userProfileData: null,
        userContacts: null,
        tickets: [],
        status: null,
        error: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
            state.userProfileData = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(createUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userData = action.payload.userData;
                state.userProfileData = action.payload.userProfileData;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(signinUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(signinUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userData = action.payload;  // Store the user document in state
            })
            .addCase(signinUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;  // Store the error message
            })
            .addCase(fetchUserProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userProfileData = action.payload;
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchUserTickets.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchUserTickets.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.tickets = action.payload;
            })
            .addCase(fetchUserTickets.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
})

//action creators are generated for each case reducer function
export const { setUser, setUserData } = userSlice.actions;

export default userSlice.reducer;