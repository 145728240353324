// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, signInWithPopup, signOut, OAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage
import { getFunctions } from 'firebase/functions';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCg7pxOfhgFXpgUEko7CVekLTCCRN5JMJQ",
  authDomain: "bluith-prod.firebaseapp.com",
  projectId: "bluith-prod",
  storageBucket: "bluith-prod.appspot.com",
  messagingSenderId: "268355056671",
  appId: "1:268355056671:web:489dce79d20da8a964112a",
  measurementId: "G-MK57T3ZGQ1"
};

// Initialize
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app);
// auth providers
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const xAuthProvider = new TwitterAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

export { auth, firestore, storage, functions, googleAuthProvider, facebookAuthProvider, xAuthProvider, appleProvider, signInWithPopup, signOut };