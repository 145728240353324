// dependencies
import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';

//components
import VertRectCard from '../../components/VertRectCard'

// lib
import { removeDuplicateArticles } from '../../lib/removeDuplicateArticles'
import { detectUserRegion } from '../../lib/userLocale';
import { ensureHttpsUrls } from '../../lib/urlProcessor';
// import { validateImageUrls } from '../../lib/validateImageUrls';

// redux 
// functions to pull the data below on page load for current,
// load search data from APIs, load global pulse from firebase
import { fetchTrendingNews } from '../../redux/slices/newsSlice';

// data that need to move to redux slice and be called via axios or fetch, firebase
import globalPulse from '../../redux/slices/global-pulse-summary.json'

function News() {
    //redux 
    const dispatch = useDispatch();
    const { newsData, newsMode, newsSettings, status, error } = useSelector((state) => state.news);

    useEffect(() => {
        const sortingMode = newsMode === 'casual' ? 'ToneDesc' : newsMode === 'intense' ? 'ToneAsc' : 'DateDesc';
        dispatch(fetchTrendingNews({ sortingMode }));
    }, [newsMode]);

    // move all preprocessing to the cloud server when no longer efficient
    const processedUrls = ensureHttpsUrls(newsData?.articles)
    const processedData = removeDuplicateArticles(processedUrls);

    // setup spinner for below centered onscreen
    // if (status === 'loading') {
    //     return <div>Loading...</div>;
    // }
    // if (status === 'failed') {
    //     return <div>Error: {error}</div>;
    // }

    return (
        <Container fluid className='mt-1 mt-sm-1'>
            <Row className='d-flex justify-content-center'>
                <VertRectCard url={globalPulse.url} image={globalPulse.image} title={globalPulse.title} publishDate={globalPulse.publish_date} />
                {processedData?.map((article, index) => {
                    if (!article.socialimage) { return null }
                    return <VertRectCard key={index} title={article.title} url={article.url} image={article.socialimage} publishDate={article.seendate} domain={article.domain} language={article.language} country={article.sourcecountry} />
                })}
            </Row>
        </Container >
    )
}

export default News