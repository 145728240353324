import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Form, FormGroup, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { reportPost } from '../redux/slices/socialSlice';

function ReportModal({ postId }) {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.user);

    const [otherReason, setOtherReason] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedReason, setSelectedReason] = useState('');

    const illegalReasons = ['Hate Speech', 'Violence', 'Child Exploitation', 'Harassment', 'Self-harm'];
    const unratedReasons = ['Sexual', 'Harassment', 'Rude', 'Offensive'];

    // Handle report action
    const handleSubmit = (e) => {
        e.preventDefault();
        let finalReason = selectedReason;
    
        if (selectedCategory === 'other') {
          finalReason = otherReason;
        }

        const user_uid = userData.user_uid;
        dispatch(reportPost({ postId: postId, user_uid, reason: finalReason, category: selectedCategory }));
        toggle();
    };

    return (
        <div>
            <p className="text-danger" onClick={toggle}>
                ⚠️
            </p>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Report Post</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit}>
                        {/* Illegal Content */}
                        <FormGroup tag="fieldset">
                            <legend>Illegal Content</legend>
                            {illegalReasons.map((reason, index) => (
                                <FormGroup key={index}>
                                    <Label className='p-0 m-0'>
                                        <Input
                                            type="radio"
                                            name="illegal"
                                            value={reason}
                                            onChange={() => {
                                                setSelectedReason(reason);
                                                setSelectedCategory('illegal');
                                            }}
                                            checked={selectedReason === reason && selectedCategory === 'illegal'}
                                        />
                                        {reason}
                                    </Label>
                                </FormGroup>
                            ))}
                        </FormGroup>

                        {/* Unrated Content */}
                        <FormGroup tag="fieldset">
                            <legend>Unrated Content</legend>
                            {unratedReasons.map((reason, index) => (
                                <FormGroup key={index}>
                                    <Label className='p-0 m-0'>
                                        <Input
                                            type="radio"
                                            name="unrated"
                                            value={reason}
                                            onChange={() => {
                                                setSelectedReason(reason);
                                                setSelectedCategory('unrated');
                                            }}
                                            checked={selectedReason === reason && selectedCategory === 'unrated'}
                                        />
                                        {reason}
                                    </Label>
                                </FormGroup>
                            ))}
                        </FormGroup>

                        {/* Other Reason */}
                        <FormGroup tag="fieldset">
                            <legend>Other Reason</legend>
                            <FormGroup>
                                <Label className='p-0 m-0'>
                                    <Input
                                        type="radio"
                                        name="other"
                                        value="Other"
                                        onChange={() => {
                                            setSelectedCategory('other');
                                            setSelectedReason('Other');
                                        }}
                                        checked={selectedCategory === 'other'}
                                    />
                                    Other
                                </Label>
                            </FormGroup>
                            {selectedCategory === 'other' && (
                                <FormGroup>
                                    <Input
                                        type="text"
                                        placeholder="Enter your reason"
                                        value={otherReason}
                                        onChange={(e) => setOtherReason(e.target.value)}
                                    />
                                </FormGroup>
                            )}
                        </FormGroup>

                        <Button type="submit" color="primary">Submit Report</Button>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ReportModal