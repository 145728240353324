// keywordSpellchecker.js

import axios from 'axios';

/**
 * Function to correct spelling mistakes in keywords using the Datamuse API.
 *
 * @param {string} keywords - The user-entered keywords as a string.
 * @returns {Promise<string>} - A promise that resolves to the corrected keywords.
 */
export async function correctKeywords(keywords) {
    if (!keywords || typeof keywords !== 'string') {
        return keywords;
    }

    const words = keywords.split(' ');

    const correctedWordsPromises = words.map(async (word) => {
        try {
            const response = await axios.get(`https://api.datamuse.com/words?sp=${word}&max=1`);
            const suggestions = response.data;
            return suggestions.length > 0 ? suggestions[0].word : word;
        } catch (error) {
            console.error(`Error fetching suggestions for word "${word}":`, error);
            return word;
        }
    });

    const correctedWords = await Promise.all(correctedWordsPromises);
    //some better logic in the future to geve better results,
    //may just be something implemented in another function

    // if (keywords === correctedWords.join(' ')) { return undefined };
    
    return correctedWords.join(' ');
}