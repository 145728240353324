import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore, storage } from '../../firebase';  // Import Firestore instance
import { collection, addDoc, getDocs, serverTimestamp, query, orderBy, limit, where, Timestamp, runTransaction, doc, increment, updateDoc } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import Sentiment from 'sentiment';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import translateText from '../../lib/translatorText';
import { moderateText } from '../../lib/moderatorText';

// chatgpt key: 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
// google translate API key: AIzaSyBczKTFqESInEK1OMnoRdkEXz1-AtYaGlk

// Google Translate API URL
const GOOGLE_TRANSLATE_URL = 'https://translation.googleapis.com/language/translate/v2';
// Google Vision Safe Search API URL
const VISION_API_URL = 'https://vision.googleapis.com/v1/images:annotate';
// ChatGPT Moderation API URL
const CHATGPT_MODERATION_URL = 'https://api.openai.com/v1/moderations';

// Your API key (you may want to move this to an environment variable for security)
const CHATGPT_API_KEY = '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';

const GOOGLE_TRANSLATE_API_KEY = 'AIzaSyBczKTFqESInEK1OMnoRdkEXz1-AtYaGlk'
// Thunk to add a post with translation, moderation, sentiment analysis, and language detection
export const addPost = createAsyncThunk(
    'social/addPost',
    async (postData, { rejectWithValue }) => {

        try {
            // Step 1: Translate post text to English and detect language using Google Translate API
            const { translatedText, detectedLanguage } = await translateText(postData.content.text, GOOGLE_TRANSLATE_API_KEY, GOOGLE_TRANSLATE_URL);

            // Step 2: Moderate the translated text using ChatGPT moderation API
            let { text, category } = await moderateText(translatedText, CHATGPT_MODERATION_URL, CHATGPT_API_KEY);

            if (category === 'flagged') {
                console.log('Content was flagged and the user was alerted.');
                return;
            } else {
                console.log('Text is safe to use:', text, 'Category:', category);
                // Proceed with saving or displaying the text
            }

            // Step 3: Analyze sentiment using the translated text
            const sentiment = new Sentiment();
            const analysis = sentiment.analyze(translatedText);
            const sentimentScoreCompare = analysis.comparative;
            const sentimentScore = analysis.score;

            let mediaUrl = null;

            // Step 4: Check if an image file is provided
            // may need to change this postData.content.media.size > 0 if not working
            if (postData.content.media.size > 0) {
                const mediaFile = postData.content.media;

                // Step 4a: Moderate the image using Google Cloud Vision API SafeSearch Detection
                // Read the image file and convert it to base64
                const readFileAsBase64 = (file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const base64String = reader.result.replace(/^data:.+;base64,/, '');
                            resolve(base64String);
                        };
                        reader.onerror = reject;
                        reader.readAsDataURL(file);
                    });
                };
                const base64Image = await readFileAsBase64(mediaFile);

                // Prepare the request to Google Cloud Vision API
                const visionAPIUrl = `${VISION_API_URL}?key=AIzaSyBczKTFqESInEK1OMnoRdkEXz1-AtYaGlk`;

                const visionRequestPayload = {
                    requests: [
                        {
                            image: {
                                content: base64Image,
                            },
                            features: [
                                {
                                    type: 'SAFE_SEARCH_DETECTION',
                                },
                            ],
                        },
                    ],
                };

                const visionResponse = await axios.post(visionAPIUrl, visionRequestPayload, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const safeSearch = visionResponse.data.responses[0].safeSearchAnnotation;

                console.log('SafeSearch results: ', safeSearch);

                const unacceptableLikelihoods = ['LIKELY', 'VERY_LIKELY'];

                if (
                    unacceptableLikelihoods.includes(safeSearch.adult) ||
                    unacceptableLikelihoods.includes(safeSearch.violence) ||
                    unacceptableLikelihoods.includes(safeSearch.racy)
                ) {
                    category = 'unrated';
                }

                // Step 4b: Upload the image to Firebase Storage
                const uniqueFileName = `${uuidv4()}_${mediaFile.name}_${Date.now()}`;
                const storageRef = ref(storage, `posts/${postData.user.user_uid}/${uniqueFileName}`);

                const uploadTask = uploadBytesResumable(storageRef, mediaFile);

                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log(`Upload is ${progress}% done`);
                        },
                        (error) => reject(error),
                        async () => {
                            mediaUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve();
                        }
                    );
                });
            }

            // Function to extract unique keywords from text
            function extractKeywords(text) {
                // Convert text to lowercase and split into words
                const words = text.toLowerCase().split(/\W+/);

                // Remove common stop words (you can customize this list)
                const stopWords = ['the', 'is', 'at', 'which', 'on', 'a', 'an', 'and', 'or', 'but', 'to', 'in', 'of', 'with', 'has', 'it', 'be', '-', 'my', '_'];

                // Filter words and ensure uniqueness by using a Set
                const uniqueKeywords = [...new Set(words.filter((word) => word && !stopWords.includes(word)))];

                return uniqueKeywords;
            }

            // Step 5: Build the post data according to your schema
            const post = {
                user: {
                    user_uid: postData.user.user_uid,
                    '@name': postData.user['@name'],
                    display_name: postData.user.display_name,
                    user_photo_url: postData.user.user_photo_url,
                },
                engagement: {
                    likes: 0,
                    comments: 0,
                    shares: 0,
                    views: 0,
                    reported: false,
                },
                content: {
                    text: postData.content.text,
                    searchable_text: translatedText, // Store the translated text for future search ...
                    keywords: extractKeywords(translatedText),
                    media: mediaUrl,
                    hashtags: postData.content.hashtags,
                    mentions: postData.content.mentions,
                    country: postData.content.location,
                    language: detectedLanguage, // Store detected language
                },
                postType: 'personal',
                sentiment: sentimentScore,
                sentiment_compare: sentimentScoreCompare,
                created: serverTimestamp(),
                moderation_category: category, // Store content category (normal, unrated)
            };

            // Step 6: Save the post to Firestore
            const postCollectionRef = collection(firestore, 'posts');
            await addDoc(postCollectionRef, post);

        } catch (error) {
            console.log(error)
            return rejectWithValue(error.message);
        }
    }
);

// Helper function to calculate timestamps for past ranges
const getTimeRange = (timeRange) => {
    const now = new Date();
    let startTime;
    switch (timeRange) {
        case '1h':
            startTime = new Date(now.setHours(now.getHours() - 1));
            break;
        case '6h':
            startTime = new Date(now.setHours(now.getHours() - 6));
            break;
        case '12h':
            startTime = new Date(now.setHours(now.getHours() - 12));
            break;
        case '1d':
            startTime = new Date(now.setDate(now.getDate() - 1));
            break;
        case '2d':
            startTime = new Date(now.setDate(now.getDate() - 2));
            break;
        case '3d':
            startTime = new Date(now.setDate(now.getDate() - 3));
            break;
        case '4d':
            startTime = new Date(now.setDate(now.getDate() - 4));
            break;
        case '5d':
            startTime = new Date(now.setDate(now.getDate() - 5));
            break;
        case '6d':
            startTime = new Date(now.setDate(now.getDate() - 6));
            break;
        case '7d':
            startTime = new Date(now.setDate(now.getDate() - 7));
            break;
        case '1m':
            startTime = new Date(now.setMonth(now.getMonth() - 1));
            break;
        case '3m':
            startTime = new Date(now.setMonth(now.getMonth() - 1));
            break;
        case '6m':
            startTime = new Date(now.setMonth(now.getMonth() - 1));
            break;
        case '1y':
            startTime = new Date(now.setFullYear(now.getFullYear() - 1));
            break;
        default:
            startTime = new Date(now.setHours(now.getHours() - 1));
            break;
    }

    return startTime ? Timestamp.fromDate(startTime) : Timestamp.fromDate(startTime);
};

// Async thunk to fetch posts with search and filter parameters
export const fetchPosts = createAsyncThunk(
    'social/fetchPosts',
    async ({ keyword, socialSettings: { socialSortType = 'created', socialSortBy = 'desc', socialQuantity = 10, socialTime, modCategory, socialCountry } }, { rejectWithValue }) => {
        try {
            const startTime = getTimeRange(socialTime); // Ensure startTime is valid

            const postCollectionRef = collection(firestore, 'posts');

            let postsQuery;

            // If the sort type is 'created', use it for both the filter and sorting.
            if (socialSortType === 'created') {
                postsQuery = query(
                    postCollectionRef,
                    where('created', '>=', startTime),  // Time-based filtering
                    orderBy('created', socialSortBy),   // Order by created first
                    limit(socialQuantity)               // Limit the number of posts
                );
            } else if (socialSortType === 'sentiment') {
                // If sorting by sentiment, still apply the time filter on 'created' but order by sentiment.
                postsQuery = query(
                    postCollectionRef,
                    where('sentiment_compare', '>=', -10),  // Time-based filtering
                    orderBy('sentiment_compare', socialSortBy), // Then order by sentiment
                    limit(socialQuantity)               // Limit the number of posts
                );
            }

            // Apply moderation category filter if specified
            if (modCategory) {
                postsQuery = query(postsQuery, where('moderation_category', '==', modCategory));
            }

            // Apply moderation category filter if specified
            if (socialCountry) {
                postsQuery = query(postsQuery, where('content.country', '==', socialCountry));
            }

            // Apply keyword search filter if provided
            if (keyword) {
                const { translatedText, detectedLanguage } = await translateText(keyword, GOOGLE_TRANSLATE_API_KEY, GOOGLE_TRANSLATE_URL);
                console.log('Translated Text:', translatedText);
                console.log('Detected Language:', detectedLanguage);
                postsQuery = query(postsQuery, where('content.keywords', 'array-contains', translatedText.toLowerCase()));
            }

            // console.log('Final Query:', postsQuery);

            // Fetch the documents based on the query
            const snapshot = await getDocs(postsQuery);

            // Map over the documents to extract data
            const posts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // console.log('Query Snapshot:', snapshot);

            return posts;
        } catch (error) {
            console.error('Error fetching posts:', error);
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk for fetching comments from Firebase
export const fetchComments = createAsyncThunk(
    'comments/fetchComments',
    async (postId, { rejectWithValue }) => {
        try {
            const commentsCollectionRef = collection(firestore, 'comments');
            const q = query(commentsCollectionRef, where('post_id', '==', postId), orderBy('created', 'asc'), limit(10));

            const snapshot = await getDocs(q);
            const commentsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            return commentsData;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk for adding a new comment to Firebase
export const addComment = createAsyncThunk(
    'comments/addComment',
    async ({ postId, commentText, userData }, { getState, rejectWithValue }) => {
        const state = getState()
        const modCategory = state.social.socialSettings.modCategory

        try {
            // Step 2: Moderate the translated text using ChatGPT moderation API
            const { text, category } = await moderateText(commentText, CHATGPT_MODERATION_URL, CHATGPT_API_KEY);

            if (category === 'flagged') {
                console.log('Content was flagged and the user was alerted.');
                return rejectWithValue('Content is flagged and cannot be posted.');
            } else if (category === 'unrated' && modCategory === 'normal') {
                alert('Content not allowed on a normal category post');
                return rejectWithValue('Content not allowed in the normal category.');
            } else {
                console.log('Text is safe to use:', text, 'Category:', category);
                // Proceed with saving or displaying the text
            }

            const newComment = {
                post_id: postId,
                comment_text: commentText,
                created: serverTimestamp(),
                user: {
                    user_uid: userData.user_uid,
                    display_name: userData.display_name,
                    user_photo_url: userData.profile_photo_url,
                    ['@name']: userData['@name'],
                }
            };
            // Firestore transaction to add comment and increment post engagement
            await runTransaction(firestore, async (transaction) => {
                // Add the new comment to the 'comments' collection
                const commentsCollectionRef = collection(firestore, 'comments');
                const commentDocRef = await addDoc(commentsCollectionRef, newComment);

                // Get reference to the specific post in the 'posts' collection
                const postDocRef = doc(firestore, 'posts', postId);

                // Update the post's engagement.comments field by incrementing it
                transaction.update(postDocRef, {
                    'engagement.comments': increment(1) // Increment the comments count by 1
                });

                return commentDocRef;
            });
            return { ...newComment };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// share social post, link to see bluith post


// report social post
// removes post if illegal, recategorizes if unrated, both are sent to admin page for review
// Async thunk to report a post
export const reportPost = createAsyncThunk(
    'posts/reportPost',
    async ({ postId, user_uid, reason, category }, { rejectWithValue }) => {
        try {
            // Create the report object to be saved
            const reportData = {
                postId,
                reporting_user: user_uid,
                reason,
                category,
                created: serverTimestamp(),
            };

            // Add the report to the "reports" collection in Firestore
            const reportsCollectionRef = collection(firestore, 'reports');
            await addDoc(reportsCollectionRef, reportData);

            // Update the reported post document with the reported category
            const postDocRef = doc(firestore, 'posts', postId);
            await updateDoc(postDocRef, { reported: true, moderation_category: category });

            return reportData;
        } catch (error) {
            console.error('Error reporting post:', error);
            return rejectWithValue(error.message);
        }
    }
);

export const socialSlice = createSlice({
    name: 'social',
    initialState: {
        posts: [],
        comments: [],
        postAdded: false,
        socialMode: 'recent',
        socialSettings: {
            socialSortType: 'created',
            socialSortBy: 'desc',
            modCategory: 'normal',
            socialTime: '1d',
            socialQuantity: '10',
            socialCountry: '',
        },
        status: 'idle',
        error: null,
    },
    reducers: {
        setPosts: (state, action) => {
            state.posts = action.payload;
        },
        setSocialMode: (state, action) => {
            state.socialMode = action.payload;
        },
        // Action to update social settings (single or multiple)
        setSocialSettings: (state, action) => {
            state.socialSettings = {
                ...state.socialSettings, // Keep the existing settings intact
                ...action.payload, // Merge the new settings into the state
            };
        },
        togglePostAdded: (state) => {
            state.postAdded = !state.postAdded;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle adding a post
            .addCase(addPost.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.postAdded = !state.postAdded;
            })
            .addCase(addPost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Handle fetching posts
            .addCase(fetchPosts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPosts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.posts = action.payload;  // Set posts to state
            })
            .addCase(fetchPosts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Handle fetchComments
            .addCase(fetchComments.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                console.log(action.payload)
                state.status = 'succeeded';
                state.comments = action.payload;
            })
            .addCase(fetchComments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Handle addComment
            .addCase(addComment.fulfilled, (state, action) => {
                console.log(action.payload)
                state.comments.push(action.payload); // Add the new comment to the state
            });
    }
})

// Action creators are generated for each case reducer function
export const { setPosts, setSocialMode, setSocialSettings } = socialSlice.actions

export default socialSlice.reducer