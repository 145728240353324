// bankSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serverTimestamp, getDocs, query, collection, where, runTransaction, increment, doc, orderBy, limit } from 'firebase/firestore';
import { firestore, functions } from '../../firebase'; // Import Firebase Firestore config
import { v4 as uuidv4 } from 'uuid'; // Generate UUID for idempotency token
import { httpsCallable } from 'firebase/functions';
import { updateAndReplaceEncryptedUids } from '../../lib/transactionUtils';

// Async thunk for creating a bank account
export const createBankAccount = createAsyncThunk(
    'bank/createAccount',
    async ({ encrypted_uid, accountType }, { rejectWithValue }) => {
        try {
            // Check if user already has an account of the specified type
            const existingAccountQuery = query(
                collection(firestore, 'bank_accounts'),
                where('encrypted_uid', '==', encrypted_uid),
                where('account_type', '==', accountType)
            );

            const existingAccountSnapshot = await getDocs(existingAccountQuery);

            if (!existingAccountSnapshot.empty) {
                // If an account of this type already exists, reject the action
                return rejectWithValue(`User already has an account of type: ${accountType}`);
            }

            // Create a transaction to handle atomic operations
            await runTransaction(firestore, async (transaction) => {
                // Step 1: Reference to the bank_totals document
                const bankTotalsDocRef = doc(firestore, 'bank_totals', 'bank_totals'); // Replace with actual document ID
                const ledgerTotalsDocRef = doc(firestore, 'ledger_totals', 'ledger_totals'); 
                
                // Step 2: Read documents
                const bankTotalsDoc = await transaction.get(bankTotalsDocRef);
                const ledgerTotalsDoc = await transaction.get(ledgerTotalsDocRef);

                if (!ledgerTotalsDoc.exists()) {
                    throw new Error('Ledger totals document does not exist.');
                }
                if (!bankTotalsDoc.exists()) {
                    throw new Error('Bank totals document does not exist.');
                }

                const bankTotalsData = bankTotalsDoc.data();
                const amount = 10000000;

                // Check if total_supply is greater than 1000
                if (bankTotalsData.total_supply_remaining <= amount) {
                    throw new Error('Not enough total supply to create a new account.');
                }

                // Step 3: Decrement total_supply by 1000 and increment bank_accounts by 1
                transaction.update(bankTotalsDocRef, {
                    total_supply_remaining: increment(-amount),
                    total_circulating: increment(amount),
                    bank_accounts: increment(1), // Use Firestore's increment function to safely increment
                });

                // Step 4: Create the new bank account
                const newAccount = {
                    encrypted_uid,
                    account_type: accountType,
                    balance: amount, // Starting balance
                    created: serverTimestamp(),
                };

                // Add the new account to 'bank_accounts' collection
                const bankAccountsCollectionRef = collection(firestore, 'bank_accounts');
                const newAccountDocRef = doc(bankAccountsCollectionRef); // Generate a new doc reference with a unique ID
                transaction.set(newAccountDocRef, newAccount);

                // Step 5: Create a transaction log for the new account creation
                const newTransaction = {
                    sender: bankTotalsData['@name'], // System creates the account
                    receiver: encrypted_uid, // The new account holder
                    type: 'account created',
                    amount: amount, // The starting balance credited to the account
                    description: `Account of type ${accountType} created with initial balance.`,
                    created: serverTimestamp(),
                    status: 'success', // Transaction status as completed
                };

                // Add the new transaction to the 'transactions' collection
                const transactionsCollectionRef = collection(firestore, 'transactions');
                const newTransactionDocRef = doc(transactionsCollectionRef); // Generate a new doc reference with a unique ID
                transaction.set(newTransactionDocRef, newTransaction);

                // Update ledger totals atomically
                transaction.update(ledgerTotalsDocRef, {
                    total_transactions: increment(1), // Increment total transactions by 1
                    tps_transactions: increment(1), // Increment TPS (transactions per second) counter
                    total_sum: increment(amount), // Add the new account balance to the total sum
                });
            });

            return { message: 'Bank account created and transaction logged successfully.' };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk for fetching transactions
export const fetchTransactions = createAsyncThunk(
    'transactions/fetchTransactions',
    async (_, { rejectWithValue }) => {
        try {
            // Reference to the transactions collection in Firestore
            const transactionsRef = collection(firestore, 'transactions');
            const transactionsQuery = query(transactionsRef, orderBy('created', 'desc'), limit(25));
            const snapshot = await getDocs(transactionsQuery);

            // Transform the snapshot data into an array of transactions
            const transactions = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

            return transactions;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Thunk for sending credits
export const sendCredits = createAsyncThunk(
    'bank/sendCredits',
    async ({ senderName, recipientName, amount }, { rejectWithValue }) => {
        try {
            // Generate a unique idempotency token
            const idempotencyToken = uuidv4();

            // Get callable Cloud Function
            const sendCreditsCallable = httpsCallable(functions, 'sendCredits');

            // Call the Cloud Function
            const response = await sendCreditsCallable({
                senderName,
                recipientName,
                amount,
                idempotencyToken,
            });

            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk to fetch transactions with search and filter parameters
export const fetchTransactions2 = createAsyncThunk(
    'bank/fetchTransactions2',
    async ({ keyword, ledgerSettings = { ledgerSortType: 'created', ledgerSortBy: 'desc', ledgerQuantity: 25 } }, { rejectWithValue }) => {
        try {
            // Destructure the values from ledgerSettings
            const { ledgerSortType, ledgerSortBy, ledgerQuantity } = ledgerSettings;

            const transactionsCollectionRef = collection(firestore, 'transactions');

            // Initialize the query variable
            let transactionsQuery;

            // If keyword is provided, create separate queries for sender and receiver
            if (keyword) {
                const senderTransactionsQuery = query(
                    transactionsCollectionRef,
                    where('sender', '==', keyword.toLowerCase()),
                    orderBy(ledgerSortType, ledgerSortBy),
                    limit(ledgerQuantity)
                );

                const receiverTransactionsQuery = query(
                    transactionsCollectionRef,
                    where('receiver', '==', keyword.toLowerCase()),
                    orderBy(ledgerSortType, ledgerSortBy),
                    limit(ledgerQuantity)
                );

                // Fetch both queries in parallel
                const [senderSnapshot, receiverSnapshot] = await Promise.all([
                    getDocs(senderTransactionsQuery),
                    getDocs(receiverTransactionsQuery),
                ]);

                // Map over both snapshots to extract data
                const senderTransactions = senderSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const receiverTransactions = receiverSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                // Combine both sender and receiver transactions
                let combinedTransactions = [...senderTransactions, ...receiverTransactions];

                // Sort combined transactions by 'created' or 'amount' based on ledgerSortType
                combinedTransactions = combinedTransactions
                    .sort((a, b) => {
                        if (ledgerSortType === 'created') {
                            return ledgerSortBy === 'desc'
                                ? b.created.toMillis() - a.created.toMillis()
                                : a.created.toMillis() - b.created.toMillis();
                        } else if (ledgerSortType === 'amount') {
                            return ledgerSortBy === 'desc'
                                ? b.amount - a.amount
                                : a.amount - b.amount;
                        }
                        return 0;
                    })
                    .slice(0, ledgerQuantity); // Limit to the specified quantity

                return combinedTransactions;

            } else {
                // If no keyword is provided, only fetch transactions based on ledgerSortType and ledgerSortBy
                transactionsQuery = query(
                    transactionsCollectionRef,
                    orderBy(ledgerSortType, ledgerSortBy),
                    limit(ledgerQuantity)
                );

                // Fetch the single query
                const snapshot = await getDocs(transactionsQuery);

                // Map over the snapshot to extract data
                const transactions = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                return transactions;
            }
        } catch (error) {
            console.error('Error fetching transactions:', error);
            return rejectWithValue(error.message);
        }
    }
);

// Initial state for the bank slice
const initialState = {
    account: null,
    transactions: [],
    ledgerMode: 'recent',
    ledgerSettings: {
        ledgerSortType: 'created',
        ledgerSortBy: 'desc',
        ledgerQuantity: '10',
    },
    status: 'idle',
    error: null,
};

// Create the Redux slice
const bankSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        setTransactions: (state, action) => {
            state.transactions = action.payload;
        },
        setLedgerMode: (state, action) => {
            state.ledgerMode = action.payload;
        },
        // Action to update ledger settings (single or multiple)
        setLedgerSettings: (state, action) => {
            state.ledgerSettings = {
                ...state.ledgerSettings, // Keep the existing settings intact
                ...action.payload, // Merge the new settings into the state
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createBankAccount.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(createBankAccount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.account = action.payload;
            })
            .addCase(createBankAccount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchTransactions.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions = action.payload;
            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(sendCredits.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(sendCredits.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(sendCredits.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchTransactions2.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchTransactions2.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions = action.payload;
            })
            .addCase(fetchTransactions2.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { setLedgerMode, setLedgerSettings, setTransactions } = bankSlice.actions

// Export the reducer to be used in the store
export default bankSlice.reducer;