// userUtils.js
export const extractSerializableUserData = (user) => {
    if (!user) return null;

    // Extract only the essential and serializable fields
    return {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        providerId: user.providerData?.[0]?.providerId || null, // fallback to null if not present
        emailVerified: user.emailVerified,
        phoneNumber: user.phoneNumber, // optional if needed
    };
};