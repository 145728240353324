import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { timeAgo } from '../../lib/timeAgo';
import { fetchUserProfile } from '../../redux/slices/userSlice';

// Sample profile component
function ProfilePage() {
  const dispatch = useDispatch();
  const { user, userData, userProfileData, status, error } = useSelector((state) => state.user);
  const [editMode, setEditMode] = useState(false);
  const [bio, setBio] = useState(userProfileData?.bio || '');
  const [location, setLocation] = useState(userProfileData?.location || '');
  const [gender, setGender] = useState(userProfileData?.gender || 'other');

  useEffect(() => {
    // Initialize data if needed, or update state on props change
    setBio(userProfileData?.bio);
    setLocation(userProfileData?.location);
    setGender(userProfileData?.gender);
  }, [userProfileData]);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch, user]);

  if (status === 'loading' || status === 'failed') {
    return <div>Loading...</div>;
  }

  // Handler for toggling edit mode
  const handleEditToggle = () => setEditMode(!editMode);

  // Handler for saving changes
  const handleSave = () => {
    // Implement save functionality to update userProfile in your backend
    console.log('Save Changes:', { bio, location, gender });
    setEditMode(false);
  };

  return (
    <div className="profile-page">
      <div className="profile-header d-flex">
        <img src={userData?.profile_photo_url} alt={`${user?.display_name}'s profile`} className="profile-photo" />
        <div className='text-center'>
          <h1>@{userData['@name']}</h1>
          <p>Member Type: {userProfileData?.member_type || 'FREE'}</p>
        </div>
      </div>

      <div className="profile-info">
        <h2>Account Information</h2>
        <hr />
        <p>Name: {userData?.display_name} </p>
        <p>Email: {userData?.email}</p>
        <p>Signup Date: {timeAgo(userData?.signup_date.seconds)}</p>
        <p>Status: {userProfileData?.account_status}</p>
      </div>

      <div className="profile-details">
        <h2>Profile Details</h2>
        <hr />
        <p>Bio: {!editMode ? bio : (
          <textarea value={bio} onChange={(e) => setBio(e.target.value)} />
        )}</p>
        <p>Location: {!editMode ? location : (
          <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} />
        )}</p>
        <p>Gender: {!editMode ? gender : (
          <select value={gender} onChange={(e) => setGender(e.target.value)}>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        )}</p>

        {/* {!editMode ? (
          <button onClick={handleEditToggle}>Edit Profile</button>
        ) : (
          <div>
            <button onClick={handleSave}>Save Changes</button>
            <button onClick={handleEditToggle}>Cancel</button>
          </div>
        )} */}
      </div>

      <div className="profile-preferences">
        <h2>Preferences & Privacy</h2>
        <hr />
        <p>Language: {userProfileData?.preferences.language}</p>
        <p>Notifications: {userProfileData?.preferences.notifications ? 'Enabled' : 'Disabled'}</p>
        <p>GeoWatch: {userProfileData?.preferences.geowatch ? 'Enabled' : 'Disabled'}</p>
        <p>Profile Visibility: {userProfileData?.preferences.privacySettings.visibility}</p>
        <p>Searchable: {userProfileData?.preferences.privacySettings.searchable ? 'Yes' : 'No'}</p>
      </div>

      {/* <div className="profile-permissions">
        <h2>Permissions</h2>
        <p>Intense Content: {userProfileData?.Permissions.intense ? 'Allowed' : 'Not Allowed'}</p>
      </div> */}
      <div className='ProfilePage__footer d-md-flex justify-content-center align-items-center text-center'>
        <Link to='/terms-privacy' className='ms-md-2 order-md-1'>Terms & Privacy</Link>
        <p>Copyright © 2024 Bluith Corp. All rights reserved.</p>
      </div>
    </div>
  );
}

export default ProfilePage;