import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ThemeSwitcher from './ThemeSwitcher';
import { auth } from '../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setUserData } from '../redux/slices/userSlice';
import { signOut } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faBook, faBookOpen, faComment, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';

function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    const overlay2 = document.getElementById("overlay2");
    overlay2.style.display = "none"; // Show the overlay
}

function SidebarNav() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);

    const handleSignOut = async () => {
        try {
            await signOut(auth)
            navigate('/bank', {replace: 'true'})
            dispatch(setUser(null))
            dispatch(setUserData(null))
            window.location.reload(true);
            console.log('User signed out successfully');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <>
            <div id="mySidebar" className="sidebar shadowed">
                <button className="closebtn p-1 bg-transparent" onClick={() => closeNav()}>×</button>
                <div onClick={() => closeNav()} className='ms-2'>
                    {/* <Link to="/home">Home</Link> */}
                    {/* <Link to="/news">News</Link> */}
                    {/* <Link to="/social">Social</Link> */}
                    {/* <Link to="/dating">Dating</Link> */}
                    {/* <Link to="/weather">Weather</Link> */}
                    {/* <Link to="/sports">Sports</Link> */}
                    {/* <Link to="/stocks">Stocks</Link> */}
                    {/* <Link to="/jobs">Jobs</Link> */}
                    {/* <Link to="/market">Market</Link> */}
                    {/* <Link to="/military">Military</Link> */}
                    {/* <Link to="/government">Government</Link> */}
                    {/* <Link to="/education">Education</Link> */}
                    {/* <Link to="/research">Research</Link> */}
                    {/* <Link to="/health">Health</Link> */}
                    {/* <Link to="/safety">Safety</Link> */}
                    {/* <Link to="/insurance">Insurance</Link> */}
                    {/* <Link to="/entertainment">Entertainment</Link> */}
                    {/* <Link to="/games">Games</Link> */}
                    <Link to="/bank"><FontAwesomeIcon icon={faBank} /><span className='fs-5 ms-2'>Bank</span></Link>
                    <Link to="/ledger"><FontAwesomeIcon icon={faBook} /><span className='fs-5 ms-2'>Ledger</span></Link>
                    {/* <Link to="/data">Data</Link> */}
                    {/* <Link to="/artifacts">Artifacts</Link> */}
                    {/* <Link to="/contacts">Contacts</Link> */}
                    <Link to="/chat"><FontAwesomeIcon icon={faComment} /><span className='fs-5 ms-2'>Chat</span></Link>
                    <Link to="/profile"><FontAwesomeIcon icon={faUser} /><span className='fs-5 ms-2'>Profile</span></Link>
                    {/* <Link to="/rewards/">Rewards</Link> */}
                    <Link to="/manual"><FontAwesomeIcon icon={faBookOpen} /><span className='fs-5 ms-2'>Manual</span></Link>
                    {/* <Link>Reset</Link> */}
                    <Link onClick={handleSignOut}><FontAwesomeIcon icon={faRightFromBracket} /><span className='fs-5 ms-2'>Sign out</span></Link>
                </div>
                <ThemeSwitcher />
            </div>

            {/* Transparent overlay */}
            <div id="overlay2" className="overlay2" onClick={closeNav}></div>
        </>
    )
}

export default SidebarNav