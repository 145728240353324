import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { firestore, storage } from '../../firebase';  // Import Firestore instance
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { addDoc, collection, query, onSnapshot, orderBy, where, serverTimestamp, limit } from 'firebase/firestore';
import CryptoJS, { enc } from 'crypto-js'; // For encryption
import { moderateText } from '../../lib/moderatorText';

// ChatGPT Moderation API URL
const CHATGPT_MODERATION_URL = 'https://api.openai.com/v1/moderations';

// Your API key (you may want to move this to an environment variable for security)
const CHATGPT_API_KEY = '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';

// Thunk to send a message
export const sendMessage = createAsyncThunk(
    'chat/sendMessage',
    async ({ message, senderId, profileImageUrl, gif, encryptionKey }, { rejectWithValue }) => {
        try {
            // Step 2: Moderate the translated text using ChatGPT moderation API
            let { text, category } = await moderateText(message, CHATGPT_MODERATION_URL, CHATGPT_API_KEY);

            if (category === 'flagged') {
                // console.log('Content was flagged and the user was alerted.');
                return rejectWithValue('Content not allowed.');
            } else {
                // console.log('Text is safe to use:', text, 'Category:', category);
                // Proceed with saving or displaying the text
            }

            // const encryptedMessage = CryptoJS.AES.encrypt(message, encryptionKey).toString();
            const chatCollection = collection(firestore, 'public_chats');

            await addDoc(chatCollection, {
                message: message,
                sender_id: senderId,
                profile_photo_url: profileImageUrl,
                created: serverTimestamp(),
                // image: image || null,
                // gif: gif || null,
            });

            // const decryptedMessage = CryptoJS.AES.decrypt(encryptedMessage, encryptionKey).toString(CryptoJS.enc.Utf8);

            // return { chatId, message, senderId };
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.message);
        }
    }
);

const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        messages: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMessage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.messages.push(action.payload);
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    },
});
//action creators are generated for each case reducer function
export const { clearError } = chatSlice.actions;

export default chatSlice.reducer;