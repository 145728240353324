import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faCheck, faXmark, faLeftLong, faSackDollar, faPeopleArrows, faArrowUpFromLine, faArrowDownUpAcrossLine, faArrowUpShortWide, faArrowUpFromBracket, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { firestore } from '../../firebase'; // Import Firestore instance
import { collection, query, where, onSnapshot, doc, orderBy, limit, getDocs } from 'firebase/firestore';
import { timeAgo } from '../../lib/timeAgo';
import { updateAndReplaceEncryptedUids } from '../../lib/transactionUtils';

function BankPage() {
  const navigate = useNavigate();
  const { user, userData, userProfileData } = useSelector((state) => state.user);
  const [transactions, setTransactions] = useState([]);
  const [bankTotals, setBankTotals] = useState();
  const [balance, setBalance] = useState(0);
  const [status, setStatus] = useState('loading')
  // Ref to store accumulated transactions
  const accumulatedTransactionsRef = useRef(new Map());

  // Subscribe to account balance and transactions on component mount
  useEffect(() => {
    // Direct reference to the 'bank_totals/bank_totals' document
    const bankTotalsDocRef = doc(firestore, 'bank_totals', 'bank_totals');

    // Snapshot listener for the specific bank_totals document
    const bankTotalsUnsub = onSnapshot(bankTotalsDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setStatus('success')
        setBankTotals(docSnapshot.data());
        // Do something with bank totals, e.g., update state or perform calculations
      } else {
        console.log('No such document!');
      }
    });

    if (!user || !userData || !userData?.encrypted_uid) return;

    // Firestore references for user's bank account and transactions
    const accountQuery = query(
      collection(firestore, 'bank_accounts'),
      where('encrypted_uid', '==', userData.encrypted_uid)
    );

    // Two queries: one for received transactions and one for sent transactions
    const receiverTransactionsQuery = query(
      collection(firestore, 'transactions'),
      where('receiver', '==', userData.encrypted_uid),
      orderBy('created', 'desc'),
      limit(5)
    );

    const senderTransactionsQuery = query(
      collection(firestore, 'transactions'),
      where('sender', '==', userData.encrypted_uid),
      orderBy('created', 'desc'),
      limit(5)
    );

    // Snapshot listener for account balance
    const accountUnsub = onSnapshot(accountQuery, (snapshot) => {
      let totalBalance = 0;
      snapshot.forEach((doc) => {
        totalBalance += doc.data().balance; // Sum all account balances
      });
      setBalance(totalBalance);
    });

    const updateCombinedTransactions = async () => {
      const transactionsMap = Array.from(accumulatedTransactionsRef.current.values());
      const processedTransactions = await updateAndReplaceEncryptedUids(transactionsMap);
      setTransactions(processedTransactions);
    };

    const handleTransactionSnapshot = (snapshot, snapshotType) => {
      snapshot.docs.forEach((doc) => {
        const transaction = { id: doc.id, ...doc.data(), snapshotType };
        accumulatedTransactionsRef.current.set(doc.id, transaction); // Use Map to avoid duplicates
      });
      updateCombinedTransactions(); // Update transactions after new snapshot
    };

    const receiverUnsub = onSnapshot(receiverTransactionsQuery, (snapshot) => {
      handleTransactionSnapshot(snapshot, 'receiver');
    });

    const senderUnsub = onSnapshot(senderTransactionsQuery, (snapshot) => {
      handleTransactionSnapshot(snapshot, 'sender');
    });

    // Cleanup listeners on component unmount
    return () => {
      accountUnsub();
      receiverUnsub();
      senderUnsub();
      bankTotalsUnsub();
    };
  }, [user, userData]);

  // Function to render each skeleton item
  const TransactionSkeleton = () => (
    <li className='skeleton-container'>
      {/* Circle skeleton for type icon */}
      <span style={{ flexBasis: '10%', textAlign: 'left' }}>
        <div className='skeleton-circle' />
      </span>
      {/* Rectangular skeleton for time */}
      <span className='d-none d-sm-block' style={{ flexBasis: '20%', textAlign: 'left' }}>
        <div className='skeleton-rect skeleton-width-md' />
      </span>
      {/* Rectangular skeleton for names */}
      <span className='notranslate' style={{ flexBasis: '20%', textAlign: 'left' }}>
        <div className='skeleton-rect skeleton-width-lg' />
      </span>
      {/* Rectangular skeleton for status */}
      <span style={{ flexBasis: '5%', textAlign: 'right' }}>
        <div className='skeleton-rect skeleton-width-sm' />
      </span>
      {/* Rectangular skeleton for amount */}
      <span className='notranslate' style={{ flexBasis: '30%', textAlign: 'left' }}>
        <div className='skeleton-rect skeleton-width-md' />
      </span>
    </li>
  );

  return (
    <div className="bank-page position-relative">
      {/* Header */}
      <div className="d-flex justify-content-start text-center">
        <div className=''>
          <FontAwesomeIcon icon={faBank} size="4x" />
          <p className='fs-3'>Bank</p>
        </div>
        <div className='text-start ms-4'>
          <p><span className='fw-bold'>Supply: </span><span className='notranslate'>∑ {status === 'loading' ? <span>loading...</span> : (bankTotals?.total_supply / 10000).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</span></p>
          <p><span className='fw-bold'>Remaining: </span><span className='notranslate'>∑ {status === 'loading' ? <span>loading...</span> : (bankTotals?.total_supply_remaining / 10000).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</span></p>
          <p><span className='fw-bold'>Circulating: </span><span className='notranslate'>∑ {status === 'loading' ? <span>loading...</span> : (bankTotals?.total_circulating / 10000).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</span></p>
          <p><span className='fw-bold'>Accounts: </span><span className='notranslate'>{status === 'loading' ? <span>loading...</span> : bankTotals?.bank_accounts.toLocaleString()}</span></p>
        </div>
      </div>
      <hr />
      {/* Profile Section */}
      <div className="profile-section">
        {/* <img
          src={userData ? userData.profile_photo_url : "https://via.placeholder.com/100"}
          alt="Profile"
          style={{ width: '100px', height: '100px' }}
          className='m-0'
        /> */}
        <p className='fw-bold'>Your account </p>
        <p className='fw-bold'>Address: </p>
        {userData ? (
          <p className='notranslate'>@{userData['@name']}</p>
        ) : (
          <p className='text-warning'>Sign in to get started</p>
        )}
        <p className='mt-3 fw-bold'>Total value: </p>
        <p className='fs-3 notranslate'>∑ {(balance / 10000).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</p>
        <div className='d-flex justify-content-center mt-4'>
          <button className='button no-wrap fs-5' onClick={() => navigate('/bank/send', { state: { encryptedUid: userData?.encrypted_uid } })}><FontAwesomeIcon icon={faArrowUp} className='me-2' />Send</button>
          <button className='button no-wrap fs-5' onClick={() => navigate('/bank/receive', { state: { username: userData['@name'] } })}><FontAwesomeIcon icon={faArrowDown} className='me-2' />Receive</button>
        </div>
      </div>

      {/* Transactions Section */}
      <div className="transactions-section">
        <h3>Recent Transactions</h3>
        <ul>
          <div className='d-flex justify-content-between'>
            <p style={{ flexBasis: '10%', textAlign: 'left' }}>Type</p>
            <p className='d-none d-sm-block' style={{ flexBasis: '20%', textAlign: 'left' }}>Time</p>
            <p style={{ flexBasis: '25%', textAlign: 'left' }}>From/To</p>
            <p style={{ flexBasis: '15%', textAlign: 'center' }}>Status</p>
            <p style={{ flexBasis: '30%', textAlign: 'left' }}>Amount</p>
          </div>
          {status === 'loading' ? (
            // Render multiple skeletons when loading
            Array(5).fill(0).map((_, index) => <TransactionSkeleton key={index} />)
          ) : (
            // Render the actual transaction data when available
            transactions.map(transaction => (
              <li className='d-flex justify-content-between' key={transaction.id}>
                <span style={{ flexBasis: '10%', textAlign: 'left' }}>
                  {transaction.type === 'P2P' ? (
                    <FontAwesomeIcon color='orange' size='lg' icon={faPeopleArrows} />
                  ) : transaction.type === "account created" ? (
                    <FontAwesomeIcon color='lightgreen' size='lg' icon={faSackDollar} />
                  ) : '?'}
                </span>
                <span className='d-none d-sm-block' style={{ flexBasis: '20%', textAlign: 'left' }}>
                  {timeAgo(transaction.created.seconds)}
                </span>
                <span className='notranslate' style={{ flexBasis: '25%', textAlign: 'left' }}>
                  @{transaction.sender === userData['@name'] ? transaction.receiver : transaction.sender}
                </span>
                <span style={{ flexBasis: '15%', textAlign: 'center' }}>
                  {transaction.status === 'success' ? (
                    <FontAwesomeIcon color='lightgreen' icon={faCheck} />
                  ) : transaction.status === 'failed' ? (
                    <FontAwesomeIcon color='red' icon={faXmark} />
                  ) : transaction.status === 'pending' ? (
                    <FontAwesomeIcon color='white' icon={faClock} />
                  ) : transaction.status === 'refund' ? (
                    <FontAwesomeIcon color='orange' icon={faLeftLong} />
                  ) : '?'}
                </span>
                <span className='notranslate' style={{ flexBasis: '30%', textAlign: 'left' }}>
                  ∑ <span className={'notranslate ' + (transaction.sender === userData['@name'] ? 'text-lightred' : 'text-lightgreen')}>
                    {transaction.sender === userData['@name'] ? '-' : '+'}
                    {(transaction.amount / 10000).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                  </span>
                </span>
              </li>
            ))
          )}
        </ul>
        <p className='text-end hover' onClick={() => navigate('/ledger', { state: { encryptedUid: userData?.encrypted_uid } })}>See More</p>
      </div>
    </div>
  );
}

export default BankPage;