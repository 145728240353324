import axios from 'axios';

/**
 * Translates post text to English if it's not already in English, and detects the language.
 * 
 * @param {string} text - The text content of the post.
 * @param {string} googleTranslateApiKey - The Google Translate API key.
 * @param {string} googleTranslateUrl - The Google Translate API URL.
 * 
 * @returns {Promise<{ translatedText: string, detectedLanguage: string }>} 
 *          An object containing the translated text and the detected language.
 */
async function translateText(text, googleTranslateApiKey, googleTranslateUrl) {
    let translatedText = text;
    let detectedLanguage = 'en'; // Default to English

    // Step 1: Detect language using a substring of the text
    if (text && text.trim() !== '') {
        // Extract a substring (first 20 characters) for language detection
        const textForDetection = text.substring(0, 10) + text.substring(20, 30);

        try {
            // Language detection API call
            const detectionResponse = await axios.post(
                googleTranslateUrl,
                {
                    q: textForDetection,
                    target: 'en',
                    format: 'text',
                },
                {
                    params: {
                        key: googleTranslateApiKey, // Use environment variable or secure storage
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log('Language detection response:', detectionResponse.data);

            detectedLanguage = detectionResponse.data.data.translations[0].detectedSourceLanguage;

            // Step 2: If the detected language is not English, translate the entire text
            if (detectedLanguage !== 'en') {
                const translationResponse = await axios.post(
                    googleTranslateUrl,
                    {
                        q: text,
                        target: 'en',
                        format: 'text',
                    },
                    {
                        params: {
                            key: googleTranslateApiKey, // Use environment variable or secure storage
                        },
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                console.log('Translation response:', translationResponse.data);

                translatedText = translationResponse.data.data.translations[0].translatedText;
            }

        } catch (error) {
            console.error('Error in language detection or translation:', error);
            throw new Error('Failed to detect or translate language.');
        }
    }

    return {
        translatedText,
        detectedLanguage
    };
}

export default translateText;