// urlPreprocessor.js

/**
 * Preprocesses an array of objects to ensure the URL fields are using https instead of http.
 * 
 * @param {Array} dataArray - The array of objects to preprocess.
 * @returns {Array} - The modified array with updated URLs.
 */
export function ensureHttpsUrls(dataArray) {
    if (!Array.isArray(dataArray)) {
        console.error("Input should be an array of objects.");
        return dataArray;
    }

    return dataArray.map((item) => {
        // Create a copy of the object to avoid mutation
        let newItem = { ...item };

        // Check and update the URL if it starts with http://
        if (newItem.url && newItem.url.startsWith("http://")) {
            newItem.url = newItem.url.replace("http://", "https://");
        }

        // Check and update the socialimage if it starts with http://
        if (newItem.socialimage && newItem.socialimage.startsWith("http://")) {
            newItem.socialimage = newItem.socialimage.replace("http://", "https://");
        }

        return newItem;
    });
}