import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Progress } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComments, addComment } from '../redux/slices/socialSlice'; // Import the slice
import { timeAgo } from '../lib/timeAgo';

const PostComments = ({ postId }) => {
    const [isPostCommentsOpen, setIsPostCommentsOpen] = useState(false);
    const [commentText, setCommentText] = useState('');
    const { comments, loading } = useSelector(state => state.social);
    const commentsEndRef = useRef(null);
    const dispatch = useDispatch();
    const { user, userData, userProfileData } = useSelector((state) => state.user);


    // console.log(user)
    // Scroll to bottom function
    const scrollToBottom = () => {
        commentsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Fetch comments when the modal opens
    useEffect(() => {
        if (isPostCommentsOpen) {
            dispatch(fetchComments(postId));
        }
    }, [isPostCommentsOpen, postId, dispatch]);

    // Run the scrollToBottom function whenever comments change
    useEffect(() => {
        scrollToBottom();
    }, [isPostCommentsOpen, comments]);

    // Handle input change
    const handleInputChange = (e) => {
        setCommentText(e.target.value);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (commentText.trim()) {
            dispatch(addComment({ postId, commentText, userData }));
            setCommentText(''); // Clear the input field after submitting
        }
    };

    return (
        <>
            <span
                className='hover'
                onClick={() => setIsPostCommentsOpen(isPostCommentsOpen ? false : true)}
            >📝</span>
            <Modal
                className=""
                size="md"
                isOpen={isPostCommentsOpen}
                toggle={() => setIsPostCommentsOpen(isPostCommentsOpen ? false : true)}
            >
                <ModalHeader toggle={() => setIsPostCommentsOpen(false)}>
                    <p className='text-secondary m-0'><strong>Comments</strong></p>
                </ModalHeader>
                <ModalBody className='w-100 pt-4'>
                    {/* Display existing comments */}
                    <div className='PostComments__comments'>
                        <ul className="PostComments__list ps-1">
                            {comments?.length > 0 ? (
                                comments?.map((comment, index) => (
                                    <div key={index} className='bg-secondary rounded mb-2 p-1'>
                                        <div className='d-flex justify-content-start pt-1'>
                                            <img src={comment.user.user_photo_url} className='profileAvatar me-2' />
                                            <p className='w-50'><strong>{comment.user['@name']}: </strong></p>
                                            <p>{timeAgo(comment.created?.seconds)}</p>
                                        </div>
                                        <p className='ms-4'>{comment.comment_text}</p>

                                    </div>
                                ))
                            ) : (
                                <p>No comments yet. Be the first to comment!</p>
                            )}
                            {/* Dummy div to scroll into view */}
                            <div ref={commentsEndRef} />
                        </ul>
                    </div>

                    {/* Comment input form */}
                    <form onSubmit={handleSubmit} className="PostComments__form d-flex">
                        <input
                            value={commentText}
                            onChange={handleInputChange}
                            placeholder="Write a comment..."
                            className="postComments__input ps-3 w-100 rounded me-2"
                        />
                        <button className='PostComments__button bg-primary rounded text-white' type="submit">⬅</button>
                    </form>
                </ModalBody>
            </Modal>

        </>
    );
};

export default PostComments;