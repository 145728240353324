import React from 'react'
import AudioPlayer from '../../components/AudioPlayer'
import audio from '../../assets/the_global_pulse_1_2024-08-31T21_24_36.mp3'
import image from '../../assets/global_pulse_article_1.WEBP'
import { Col, Container, Row } from 'reactstrap'
import globalPulse from '../../redux/slices/global-pulse-summary.json'

function NewsArticle() {
    return (
        <Container jumbotron="true" className='NewsArticle p-4 mt-5'>
            <Row className='text-center'>
                <Col>
                    <div>
                        <img className='NewsArticle__image' src={image} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='text-center my-2'>
                    <AudioPlayer audio={audio} />
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col lg={6} className='text-start'>
                    <h1>The Global Pulse - Article 1</h1>
                    {globalPulse.text?.map((paragraph, index) => (
                        <p key={index}>
                            {paragraph}
                        </p>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default NewsArticle