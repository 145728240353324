import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function ReceivePage() {
    const navigate = useNavigate();
    const location = useLocation();
    const username = location.state?.username
    return (
        <div className='bank-page' style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <div className='ReceivePage__header d-flex position-relative'>
                {/* Back Button */}
                <p
                    className='fs-1'
                    onClick={() => navigate('/bank')}
                    style={{ marginBottom: '20px', cursor: 'pointer' }}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </p>
                <p className='fs-3 position-absolute'>Receive Funds</p>
            </div>


            {/* QR Code Display */}
            <div style={{ textAlign: 'center' }}>
                <QRCodeCanvas style={{ textAlign: 'center', padding: '10px', backgroundColor: 'white' }} value={username} size={200} />
                <p className='mt-3'>@{username}</p>
            </div>
            {/* <div className='d-flex justify-content-center'>
                <button className='button mt-5'>Copy Address</button>
            </div> */}
        </div>
    );
}

export default ReceivePage;