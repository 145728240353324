// a vertical oriented card for displaying inforamtion in a list
// params imgUrl, title, seendate, socialimage,domain, language, sourcecountry,
import React from 'react'
import { useNavigate } from 'react-router-dom';
import no_image from '../assets/walking_wallets_chinese.jpeg'
const { formatISOTime } = require('../lib/timeFormatter');

function VertRectCard({ url, image, title, publishDate, domain, language, country }) {
    const navigate = useNavigate();
    const handleClick = () => {
        if (url === "/news/news-article") {
            navigate(url);
        } else {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };


    const handleError = (e) => {
        e.target.src = no_image; // Fallback image
    }

    return (
        <div onClick={handleClick} className='VertRectCard rounded m-auto hover p-0 mb-2'>
            <img className='rounded p-1' alt={title} src={image} onError={handleError} />
            <div className='p-1 m-1 mx-2'>
                <p className='fs-6 fw-bold'>{title?.slice(0, 135)}</p>
                <div className='d-flex justify-content-between mt-1'>
                    <p className='fs-6 text-secondary'>{domain}</p>
                    <p className='fs-6 text-secondary'>{language}</p>
                </div>
                <div className='d-flex justify-content-between'>
                    <p className='fs-6 text-secondary'>{formatISOTime(publishDate)}</p>
                    <p className='fs-6 text-secondary'>{country}</p>
                </div>
            </div>
        </div>
    )
}

export default VertRectCard