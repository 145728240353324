import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleArrows, faHandHoldingDollar, faCheck, faXmark, faLeftLong, faBank, faSackDollar, faBook } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransactions, fetchTransactions2 } from '../../redux/slices/bankSlice';
import { timeAgo } from '../../lib/timeAgo';
import { useLocation } from 'react-router-dom';
import { collection, query, where, onSnapshot, doc, orderBy, limit, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Import Firestore instance
import { updateAndReplaceEncryptedUids } from '../../lib/transactionUtils';
import { setTransactions } from '../../redux/slices/bankSlice';

function LedgerPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const encryptedUid = location.state?.encryptedUid;
  const [ledgerTotals, setLedgerTotals] = useState();
  const [processedTransactions, setProcessedTransactions] = useState([]);

  // Select transactions from Redux state
  const { transactions, status, error } = useSelector((state) => state.bank);
  // const [status, setStatus] = useState('loading')

  useEffect(() => {
    // Function to fetch the ledger totals document
    const fetchLedgerTotals = async () => {
      try {
        const ledgerTotalsDocRef = doc(firestore, 'ledger_totals', 'ledger_totals');
        const docSnapshot = await getDoc(ledgerTotalsDocRef);

        if (docSnapshot.exists()) {
          setLedgerTotals(docSnapshot.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching ledger totals:', error);
      }
    };

    // Fetch once when the component mounts
    fetchLedgerTotals();

  }, []); // Empty dependency array to ensure it only runs once

  useEffect(() => {
    const processTransactions = async () => {
      if (encryptedUid) {
        const { payload: fetchedTransactions } = await dispatch(fetchTransactions2({ keyword: encryptedUid }));
        const updatedTransactions = await updateAndReplaceEncryptedUids(fetchedTransactions, 25);
        setProcessedTransactions(updatedTransactions);
        dispatch(setTransactions(updatedTransactions)); // Optionally update Redux state with processed transactions
      } else {
      // If `username` is not present, dispatch `fetchTransactions`
      dispatch(fetchTransactions());
      }
    };

    processTransactions();

  }, [dispatch, encryptedUid]);

  // const processAndSetTransactions = async (combinedTransactions) => {
  //   try {
  //     const processedTransactions = await updateAndReplaceEncryptedUids(combinedTransactions, 25);
  //     dispatch(setTransactions(processedTransactions));
  //   } catch (error) {
  //     console.error('Error processing transactions:', error);
  //   }
  // };

  return (
    <Container>
      {/* Header */}
      <div className="d-flex justify-content-start text-center m-3">
        <div className=''>
          <FontAwesomeIcon icon={faBook} size="4x" />
          <p className='fs-5'>Ledger</p>
        </div>
        <div className='text-start ms-4'>
          <p><span className='fw-bold'>Transactions: </span><span className='notranslate'>{status === 'loading' ? <span>loading ...</span> : ledgerTotals?.total_transactions.toLocaleString()}</span></p>
          <p><span className='fw-bold'>Total: </span><span className='notranslate'>∑ {status === 'loading' ? <span>loading ...</span> : (ledgerTotals?.total_sum / 10000).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</span></p>
          <p><span className='fw-bold'>TPS: </span><span className='notranslate'>{status === 'loading' ? <span>loading ...</span> : ledgerTotals?.transactions_per_second.toLocaleString()}</span></p>
        </div>
      </div>
      <hr className='m-0' />
      <div className="transactions-section">
        <ul>
          <div className='transaction-row'>
            <p style={{ flexBasis: '10%', textAlign: 'left' }}>Type</p>
            <p style={{ flexBasis: '20%', textAlign: 'left' }}>Time</p>
            <p style={{ flexBasis: '15%', textAlign: 'left' }}>From</p>
            <p style={{ flexBasis: '15%', textAlign: 'left' }}>To</p>
            <p style={{ flexBasis: '10%', textAlign: 'center' }}>Status</p>
            <p style={{ flexBasis: '15%', textAlign: 'left' }}>Amount</p>
          </div>
          {transactions?.length <= 0 ?
            <p>No transactions found</p>
            :
            transactions?.map(transaction => (
              <li className='transaction-row' key={transaction.id}>
                <span style={{ flexBasis: '10%', textAlign: 'left' }}>{transaction.type === 'P2P' ? <FontAwesomeIcon color='orange' size='lg' icon={faPeopleArrows} /> : transaction.type === 'loan' ? <FontAwesomeIcon size='xl' icon={faHandHoldingDollar} /> : transaction.type === "account created" ? <FontAwesomeIcon color='lightgreen' size='lg' icon={faSackDollar} /> : '?'}</span>
                <span style={{ flexBasis: '20%', textAlign: 'left' }}>{timeAgo(transaction.created.seconds)}</span>
                <span className='notranslate' style={{ flexBasis: '15%', textAlign: 'left' }}>{transaction.sender === "BANK" ? '@' + transaction.sender : transaction.sender?.length < 35 ? '@' + transaction.sender : transaction.sender?.slice(0, 10) + '...'}</span>
                <span className='notranslate' style={{ flexBasis: '15%', textAlign: 'left' }}>{transaction.receiver === "BANK" ? '@' + transaction.receiver : transaction.receiver?.length < 35 ? '@' + transaction.receiver : transaction.receiver?.slice(0, 10) + '...'}</span>
                <span style={{ flexBasis: '10%', textAlign: 'center' }}>{transaction.status === 'success' ? <FontAwesomeIcon color='lightgreen' icon={faCheck} /> : transaction.status === 'failed' ? <FontAwesomeIcon color='red' icon={faXmark} /> : transaction.status === 'pending' ? <FontAwesomeIcon color='white' icon={faClock} /> : transaction.status === 'refund' ? <FontAwesomeIcon color='orange' icon={faLeftLong} /> : '?'}</span>
                <span className='notranslate' style={{ flexBasis: '15%', textAlign: 'left' }}>∑ {(transaction.amount / 10000).toFixed(4)}</span>
              </li>
            ))
          }
        </ul>
      </div>
    </Container>
  )
}

export default LedgerPage