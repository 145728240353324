import React, { useEffect, useState } from 'react';
import logo from '../assets/placeholder-image.png'
import PostCard from './PostCard';
import { addPost } from '../redux/slices/socialSlice';
import { useDispatch, useSelector } from 'react-redux';

function PostCreator({ onPostSubmit }) {
    const [text, setText] = useState('');
    const [media, setMedia] = useState([]);
    const [mediaPreview, setMediaPreview] = useState(null);  // Store the preview URL
    const [hashtags, setHashtags] = useState('');
    const [mentions, setMentions] = useState('');
    const [newPost, setNewPost] = useState(null);
    const dispatch = useDispatch();
    const { user, userData } = useSelector((state) => state.user);
    const langCountry = navigator.language;

    // Update the newPost state when any of the form fields or userData changes
    useEffect(() => {
        if (userData) {
            setNewPost({
                user: {
                    user_uid: userData.user_uid,  // User UID from Redux
                    '@name': userData['@name'],
                    display_name: userData.display_name,
                    user_photo_url: userData.profile_photo_url,
                },
                content: {
                    text,
                    media: mediaPreview,
                    hashtags: hashtags.split(',').map((tag) => tag.trim()),
                    mentions: mentions.split(',').map((mention) => mention.trim()),
                    location: langCountry.split('-')[1],
                    language: langCountry.split('-')[0],
                },
                created: { seconds: Date.now() / 1000 },
            });
        }
    }, [text, media, hashtags, mentions, userData, langCountry]);

    const handleMediaUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                if (file.type === 'image/gif') {
                    // For GIFs, do not resize, but check file size
                    const MAX_GIF_SIZE = 1 * 1024 * 1024; // 5MB limit (adjust as needed)
                    if (file.size > MAX_GIF_SIZE) {
                        alert('GIF is too large. Please upload a GIF smaller than 1MB.');
                    } else {
                        setMedia(file);
                        setMediaPreview(URL.createObjectURL(file));
                    }
                } else {
                    // Process other image types
                    reduceFileSize(file)
                        .then((processedFile) => {
                            setMedia(processedFile);
                            setMediaPreview(URL.createObjectURL(processedFile));
                        })
                        .catch((err) => {
                            console.error('Error processing image:', err);
                            alert('Failed to process the image.');
                        });
                }
            } else {
                alert('Please upload a valid image file.');
            }
        }
    };

    // Function to reduce the file size of non-GIF images while keeping the original dimensions
    const reduceFileSize = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (event) => {
                img.onload = async () => {
                    // Create a canvas with the original image dimensions
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');

                    // Draw the original image onto the canvas
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    // Function to get the blob with the desired quality
                    const getBlob = (quality) => {
                        return new Promise((resolve) => {
                            canvas.toBlob(
                                (blob) => {
                                    resolve(blob);
                                },
                                'image/webp',  // Convert to WebP
                                quality        // Set the quality
                            );
                        });
                    };

                    // Optimize quality adjustment: start from 0.9 and binary search for quality
                    let quality = 0.9;
                    let minQuality = 0.1;
                    let maxQuality = 1.0;
                    let blob = await getBlob(quality);

                    while ((blob.size > 150 * 1024 || blob.size < 75 * 1024) && (maxQuality - minQuality > 0.05)) {
                        // Adjust quality using a binary search approach
                        if (blob.size > 150 * 1024) {
                            maxQuality = quality;
                        } else if (blob.size < 75 * 1024) {
                            minQuality = quality;
                        }
                        console.log("running")

                        quality = (minQuality + maxQuality) / 2;
                        blob = await getBlob(quality);
                    }

                    // Convert the blob back to a File object
                    const newFile = new File([blob], `${file.name.split('.')[0]}.webp`, {
                        type: 'image/webp',
                        lastModified: Date.now(),
                    });

                    resolve(newFile);
                };

                img.onerror = () => {
                    reject(new Error('Failed to load image'));
                };

                img.src = event.target.result;
            };

            reader.onerror = () => {
                reject(new Error('Failed to read file'));
            };

            reader.readAsDataURL(file);
        });
    };

    // console.log(postData)
    const handleSubmit = (e) => {
        e.preventDefault();


        // Ensure user data exists before submitting
        // if (!userData) {
        //     console.error("User data is not available.");
        //     return;
        // }

        const postData = {
            ...newPost,
            content: {
                ...newPost.content,
                media: media,  // Use the actual Firebase storage URL after upload
            }
        };

        // Dispatch the post to Redux and Firebase
        dispatch(addPost(postData));

        // Clear form
        setText('');
        setMedia([]);
        setMediaPreview(null);
        setHashtags('');
        setMentions('');
    };

    return (
        <div className='post-creator'>
            <form onSubmit={handleSubmit}>
                <textarea
                    placeholder={`What's on your mind?\n(add #hashtags, add @mentions)`}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <div className="post-creator-actions">
                    <label className="image-upload fs-3 px-2 bg-secondary">
                        <input
                            type="file"
                            onChange={handleMediaUpload}
                            accept="image/*"
                        />🌄
                    </label>
                    <button className='' type="submit">⬅</button>
                </div>
                {/* Preview Post if there is content */}
                {(text || mediaPreview) && (
                    <div className='mt-3'>
                        <PostCard post={newPost} />
                    </div>
                )}
            </form>
        </div>
    );
}

export default PostCreator;