import React from 'react';
import { timeAgo } from '../lib/timeAgo';
import PostComments from './PostComments';
import ReportModal from './ReportModal';

function PostCard({ post }) {

    return (
        <div className="post-card">
            <div className="post-header">
                <div className='d-flex align-items-center'>
                    <img src={post.user?.user_photo_url} alt={post.user?.display_name} />
                    <div className="post-user-info">
                        <h4>{post.user?.display_name}</h4>
                        <p>@{post?.user['@name']}</p>
                    </div>
                </div>
                <div>
                    <p>{timeAgo(post.created?.seconds)}</p>
                    <p>{post.content?.language}-{post.content?.country}</p>
                </div>
            </div>
            <div className="post-content">
                <p>{post.content?.text}</p>
                {post.content?.media && (
                    // this despoarately needs to be changed to image or video becasue this will get out of control
                    <div>
                        { post.content.media?.includes('.gif') || post.content.media?.includes('.webp') || post.content.media?.includes('blob:') ? (
                            <img src={post.content?.media} alt="Post media" />
                        ) : post.content.media?.includes('.mp4') || post.content.media?.includes('.mov') || post.content.media?.includes('.avi') ? (
                            <video src={post.content?.media} controls />
                        ) : null}
                    </div>
                )}
                <p>{post.content?.hashtags.join(' ')}</p>
            </div>
            {post.cta?.buttonText && (
                <a href={post.cta?.url} className="cta-button">
                    {post.cta?.buttonText}
                </a>
            )}
            <div className="post-footer">
                <p>𝛴 {post.engagement?.likes || 3.23}</p>
                <p className='d-flex'><PostComments postId={post?.id}/> {post.engagement?.comments || 0}</p>
                {/* <p>⬆</p> */}
                {/* <p>👀 {post.engagement?.views || 345}</p> */}
                {/* <span className="text-danger" onClick={handleReport}> */}
                    <ReportModal postId={post.id}/>
                {/* </span> */}
            </div>
        </div>
    );
}

export default PostCard;