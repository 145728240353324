// Globe3D.js
import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { TextureLoader } from 'three';
import { useLoader } from '@react-three/fiber';
import globeTexture from '../assets/blue_land.jpg'

const Globe = () => {
    const globeRef = useRef();
    const earthTexture = useLoader(TextureLoader, globeTexture); // Replace with a link to the texture you downloaded.

    // Rotate the globe continuously
    useFrame(() => {
        if (globeRef.current) {
            globeRef.current.rotation.y += 0.001; // Adjust the speed of rotation
        }
    });
    const EventMarker = ({ position }) => {
        return (
            <mesh position={position}>
                <sphereGeometry args={[0.01, 32, 32]} />
                <meshStandardMaterial color="darkred" />
            </mesh>
        );
    };
    return (
        <mesh ref={globeRef} scale={[2.5, 2.5, 2.5]} rotation={[0, 0, 0]}>
            <sphereGeometry args={[1, 32, 32]} />
            <meshStandardMaterial map={earthTexture} />
            {/* use event marker below to create a map of events in world */}
            <EventMarker position={[1, 0.2, 0]} />
        </mesh>
    );
};

const Globe3D = () => {
    return (
        <Canvas>
            <ambientLight intensity={10} />
            <directionalLight position={[1, 2, 2]} />
            <Globe />
            <OrbitControls />
        </Canvas>
    );
};

export default Globe3D;