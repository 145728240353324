import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import QRCodeScanner from '../../components/QRCodeScanner';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Import Firestore instance
import { sendCredits } from '../../redux/slices/bankSlice';

function SendPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, userData } = useSelector((state) => state.user);
  const { status, error } = useSelector((state) => state.bank);
  const [errors, setErrors] = useState({ recipient: '', amount: '' }); // Error state
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const [balance, setBalance] = useState(0);

  // Subscribe to account balance and transactions on component mount
  useEffect(() => {
    if (!user || !userData) return;

    // Firestore references for user's bank account and transactions
    const accountQuery = query(
      collection(firestore, 'bank_accounts'),
      where('encrypted_uid', '==', userData.encrypted_uid)
    );

    // Snapshot listener for account balance
    const accountUnsub = onSnapshot(accountQuery, (snapshot) => {
      let totalBalance = 0;
      snapshot.forEach((doc) => {
        totalBalance += doc.data().balance; // Sum all account balances
      });
      setBalance(totalBalance);
    });

    // Cleanup listeners on component unmount
    return () => {
      accountUnsub();
    };
  }, [user, userData]);

  const handleScan = (data) => {
    console.log('running')
    if (data) {
      setRecipient(data);
      setShowScanner(false); // Close scanner after successful scan
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const validateRecipient = (value) => {
    if (!value) return 'Recipient cannot be empty.';
    if (userData['@name'] === value.toLowerCase().trim()) return 'Sender cannot be recipient.';
    return '';
  };

  const validateAmount = (value) => {
    if (!value || parseFloat(value) <= 0) return 'Amount must be greater than 0.';
    const adjustedAmount = Math.floor(value * 10000);
    if (balance < adjustedAmount) return 'Insufficient balance.';
    return '';
  };

  const handleSend = () => {
    // Validate recipient and amount
    const recipientError = validateRecipient(recipient);
    const amountError = validateAmount(amount);

    setErrors({ recipient: recipientError, amount: amountError });

    // If any errors, stop the send operation
    if (recipientError || amountError) return;

    // Calculate the adjusted amount in a local variable
    const adjustedAmount = Math.floor(amount * 10000);

    if (balance < adjustedAmount) {
      alert('Insufficient balance.');
      return;
    }

    // Dispatch sendCredits thunk
    dispatch(
      sendCredits({
        senderName: userData['@name'],
        recipientName: recipient.toLowerCase().trim(),
        amount: adjustedAmount,
      })
    );
    navigate('/bank');
  };

  return (
    <div className='bank-page' style={{ padding: '20px' }}>
      <div className='ReceivePage__header d-flex justify-content-between align-items-center'>
        {/* Back Button */}
        <p
          className='fs-1'
          onClick={() => navigate('/bank')}
          style={{ marginBottom: '20px', cursor: 'pointer' }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </p>
        <p className='fs-3 mb-4'>Send Funds</p>
        {/* QR Code Icon to Open Scanner */}
        <div style={{ textAlign: 'right', marginBottom: '20px' }}>
          <FontAwesomeIcon
            icon={faQrcode}
            size="2x"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowScanner(!showScanner)}
          />
        </div>
      </div>


      {showScanner && <QRCodeScanner onScan={handleScan} />}
      {/* add recents options for last 5 users, pull from localstorage */}
      {/* Recipient Input Field */}
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="recipient">Recipient</label>
        <input
          type="text"
          id="recipient"
          value={recipient}
          onChange={(e) => {
            // Remove any '@' symbol from the input
            const value = e.target.value.replace(/@/g, '');
            setRecipient(value)
          }}
          style={{ width: '100%', padding: '10px', marginTop: '10px', border: errors.recipient ? '1px solid red' : 'initial' }}
          placeholder='@ Enter user @name'
        />
        {errors.recipient && <p style={{ color: 'red', marginTop: '5px' }}>{errors.recipient}</p>}
      </div>

      {/* Amount Input Field */}
      <div style={{ marginBottom: '20px' }}>
        <div className='d-flex justify-content-between'>
          <label htmlFor="amount">Amount</label>
          <p onClick={() => setAmount((balance / 10000).toFixed(4))}>Max: ∑ {(balance / 10000).toFixed(4)}</p>
        </div>

        <input
          type="text"
          id="amount"
          value={amount}
          onChange={(e) => {
            const value = e.target.value;

            // Allow only numbers and up to 4 decimal places
            if (/^\d*\.?\d{0,4}$/.test(value)) {
              setAmount(value);
            }
          }}
          style={{ width: '100%', padding: '10px', marginTop: '10px', border: errors.amount ? '1px solid red' : 'initial' }}
          placeholder='∑ Enter amount'
          step="0.0001"
        />
        {errors.amount && <p style={{ color: 'red', marginTop: '5px' }}>{errors.amount}</p>}
      </div>

      {/* Pay Now Button */}
      <button className='button m-0'
        style={{ width: '100%', padding: '10px' }}
        // change the number back to DecaSum and send to other user
        onClick={handleSend}
        disabled={status === 'loading'}
      >
        Pay Now
      </button>
    </div>
  );
}

export default SendPage;