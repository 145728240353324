import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PublicChat from './PublicChat';

function ChatPage() {

    return (
        <Container fluid className='g-0'>
            <Row>
                <Col lg={6} className='mx-auto'>
                    <PublicChat />
                </Col>
            </Row>
        </Container>
    )
}

export default ChatPage