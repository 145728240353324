import { faArrowDown, faArrowUp, faBars, faCalendar, faCalendarAlt, faCheck, faInfo, faPeopleArrows, faPhone, faQrcode, faSackDollar, faSearch, faTicket, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faBook, faComment, faUser, faQuestionCircle, faInfoCircle, faRoad, faHeadset, faUsersCog } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { submitTicket, fetchUserTickets } from '../../redux/slices/userSlice';
import { timeAgo } from '../../lib/timeAgo';

function ManualPage() {

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [message, setMessage] = useState('');
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const { user, userData, userProfileData, tickets, status, error } = useSelector((state) => state.user);

  // Fetch tickets by @name when the component loads
  useEffect(() => {
    if (userData?.['@name']) {
      dispatch(fetchUserTickets(userData['@name']));
    }
  }, [dispatch, userData, reload]);

  const handleSubmit = () => {
    // Validate the message before submission
    if (!message.trim()) return;

    // Dispatch the action to submit the ticket
    dispatch(
      submitTicket({
        message,
        email: userData.email,
        name: userData.display_name,
        username: userData['@name'],
      })
    );

    // Clear the input after submission
    setMessage('');
    setReload(!reload);
  };

  // Define the sections and their content
  const sections = [
    {
      label: 'Bank',
      icon: faBank,
      content: (
        <div className="get-help-box">
          <p>The Bank feature is similar to a digital wallet, and it can be used the same way. The diferrences are outline here:</p>
          <ul>
            <li className='mb-1'><strong className='text-grey'>Sum</strong> - The native currency of bluith symbolized like so ∑</li>
            <li className='mb-1'><strong className='text-grey'>DecaSum</strong> - Sum * 10000. For example, 1 Sum = 10000 DecaSum. For the most part this will be irrelevant to the user.</li>
            <li className='mb-1'><strong className='text-grey'>Supply</strong> - Total available supply of Sum. This can change over time.</li>
            <li className='mb-1'><strong className='text-grey'>Remaining</strong> - Not in circulation. The amount remaining to be distributed.</li>
            <li className='mb-1'><strong className='text-grey'>Circulating</strong> - Sum in circulation. The Sum available for users to send back and forth.</li>
            <li className='mb-1'><strong className='text-grey'>Accounts</strong> - Total accounts that have been created</li>
            <li className='mb-1'><strong className='text-grey'>Your address</strong> - A unique @name is created when you sign up, and when you go to send Sum to other users you will enter just the name. For example: @bobbykeel, enter bobbykeel in recipient field.</li>
            <li className='mb-1'><strong className='text-grey'>Total Value</strong> - Total amount of Sum in your account. In the future this will represent a total value including assets.</li>
            <li className='mb-1'><strong className='text-grey'>Send</strong> - Click <span className='bg-success text-white p-1 my-2 rounded'><FontAwesomeIcon icon={faArrowUp} /> Send</span> to send Sum to another user. Enter their @name as name, then enter the amount to send, and click Pay Now. Click the scanner icon <FontAwesomeIcon icon={faQrcode} size='lg' /> to scan a QR code instead.</li>
            <li className='mb-1'><strong className='text-grey'>Receive</strong> - Click <span className='bg-success text-white p-1 my-2 rounded'><FontAwesomeIcon icon={faArrowDown} /> Receive</span> for a scannable QR code with your address.</li>
            <li className='mb-1'><strong className='text-grey'>Recent Transactions</strong> - A list of 5 most recent transactions. click See more to see your past transactions in the Ledger.</li>
            <li className='mb-1'><strong className='text-grey'>Type</strong> - Icons that represent the type of transaction. Currently, there are 2 types: P2P <FontAwesomeIcon color='orange' size='lg' icon={faPeopleArrows} />, and account creation <FontAwesomeIcon color='lightgreen' size='lg' icon={faSackDollar} />.</li>
            <li className='mb-1'><strong className='text-grey'>From/To</strong> - Shows the other party from your transaction.</li>
            <li className='mb-1'><strong className='text-grey'>Status</strong> - Several options are possible, currently you will either see a success checkmark <FontAwesomeIcon color='lightgreen' icon={faCheck} /> or an error message during sending. Later we will implement more.</li>
            <li className='mb-1'><strong className='text-grey'>Amount</strong> - Total amount transacted and color coded.</li>
          </ul>
          <p className='text-grey'>Safeguards</p>
          <ul>
            <li>You will not be able to send Sum to an account that does not exist.</li>
            <li>Transactions will be reversible in future.</li>
          </ul>
        </div>
      ),
    },
    {
      label: 'Ledger',
      icon: faBook,
      content: (
        <div className="get-help-box">
          <p>The Ledger is similar to a digital one. Here you can see all transactions, and search the database by @name.</p>
          <ul>
            <li className='mb-1'><strong className='text-grey'>Transactions</strong> - Total number of transactions for all time.</li>
            <li className='mb-1'><strong className='text-grey'>Total</strong> - Total Sum that has been transacted for all time.</li>
            <li className='mb-1'><strong className='text-grey'>TPS</strong> - Transactions per second, calculated every 60 seconds.</li>
            <li className='mb-1'><strong className='text-grey'>Search</strong> - Click the search icon <FontAwesomeIcon icon={faSearch} size='lg' /> top right to search transactions by address. Looks something like "08h473rb108347g0f1347fg103487g..." if you have it. Otherwise, you can only see @names for your transactions for now.</li>
            <li className='mb-1'><strong className='text-grey'>Transaction section</strong> - See Bank section for details on this</li>
          </ul>
        </div>
      ),
    },
    {
      label: 'Chat',
      icon: faComment,
      content: (
        <div className="get-help-box">
          <p>Chat is a public feed where everyone will gather to discuss whatever.</p>
          <ul>
            <li className='mb-1'><strong className='text-grey'>Emojis</strong> - provided emojies if needed</li>
            <li className='mb-1'><strong className='text-grey'>Moderation</strong> - Bullying, threats, self-harm, others are not allowed. These are auto-moderated. Please be courteous otherwise.</li>
          </ul>
        </div>
      ),
    },
    {
      label: 'Profile',
      icon: faUser,
      content: (
        <div className="get-help-box">
          <p>Profile is where your information will be housed. You can make changes here in the future.</p>
          <ul>
            <li className='mb-1'><strong className='text-grey'>Status</strong> - Types: expulsion = permanently locked out, suspended = temporary under investigation, active</li>
            <li className='mb-1'><strong className='text-grey'>Member type</strong> - currently the only option is FREE</li>
            <li className='mb-1'><strong className='text-grey'>Notifications</strong> - currently enabled but does not do anything. future state will send notifications for transactions and GeoWatch notification to be revealed later.</li>
            <li className='mb-1'><strong className='text-grey'>GeoWatch</strong> - Sounds ominous -- its not --, but its not currently doing anything,. Future feature to be revealed later.</li>

            <li className='mb-1'><strong className='text-grey'>Profile Visibility</strong> - in future will power the visibility of your account.</li>
            <li className='mb-1'><strong className='text-grey'>Searchable</strong> - same</li>
          </ul>
        </div>
      ),
    },
    // Adding a separator after Profile
    { separator: true },
    {
      label: 'About Us',
      icon: faInfoCircle,
      content: (
        <div className="get-help-box mb-5">
          <p><strong className='text-grey'>Problem</strong></p>
          <p>Traditional cryptocurrencies are intimidating, complex, and not user-friendly for the average person.</p>
          <p><strong className='text-grey'>Solution</strong></p>
          <p>Bluith solves this by offering a risk-free, intuitive experience that combines the best of crypto and gaming, letting users transact with Sum ∑, our in-game currency. It’s not just a token; it’s a complete economy that reflects real-world scenarios and possibilities.</p>
          <p><strong className='text-grey'>So... What is bluith?</strong></p>
          <p>Imagine a digital world where you can open a coffee shop, invest in the latest tech company, or become a political leader — each decision you make has a direct influence on the digital society. Bluith is designed to reflect real-world scenarios, giving you the tools to dive into a digital economy where you can trade stocks, real estate, commodities, and even vote for your govenerning leader.</p>
          <p>From the teenager learning about finance, to the parent managing their virtual grocery shopping, whether you’re bartering with neighbors, running a digital business, or navigating market fluctuations, the interface remains as simple as placing an online order.</p>
          <p>You can begin trading and transacting immediately with Sum ∑. Unlike complex Web 3 setups, Bluith operates on a Web 2 platform for simplicity. In the future, transactions will be securely processed on Bluith's blockchain backend, maintaining transparency and security. Sum is free to use. Its value is dynamically shaped by the economy of Bluith — rising and falling with market activities, user interactions, and world events.</p>
          <p>Step into a world where your choices define the future and your creativity shapes the market. Join Bluith, discover new opportunities, and be an architect of a growing digital socio-economy.</p>
          <i>– Play the world, live the game.</i>

          {/* <p><strong className='text-grey'>Where we think the focus should be</strong></p>
          <ul>
            <li className='mb-1'><strong>Security & Privacy</strong></li>
            <li className='mb-1'><strong>Ease of Use & Accessibility</strong></li>
            <li className='mb-1'><strong>Refundable transactions</strong></li>
            <li className='mb-1'><strong>Never lose account access</strong></li>
            <li className='mb-1'><strong>Innate non-fiat value</strong></li>
            <li className='mb-1'><strong>Speed</strong></li>
            <li className='mb-1'><strong>Governance & Community</strong></li>
            <li className='mb-1'><strong>Backup & Backend use of decentralization</strong></li>
          </ul> */}
        </div>
      ),
    },
    {
      label: 'Roadmap',
      icon: faRoad,
      content: (
        <div className="get-help-box">
          <p>The goal is to make crypto easy for the common person.</p>
          <ul>
            <li className='mb-1'><span className='text-warning'>Began Work 9-1-2024</span></li>
            <li className='mb-1'><strong className='text-grey'>Prime</strong> - Release MVP, get users feedback, iterate: Bank, Ledger, Chat, Profile, Manual, Theme Switcher. <span className='text-warning'>Launched 10-7-2024 ***Current Progress***</span></li>
            <li className='mb-1'><strong className='text-grey'>Prime 1.5</strong> - Release the second piece of the MVP, get users feedback, iterate: Artifacts, Exchange, Market, Games, Government, Stats, GeoWatch</li>
            <li className='mb-1'><strong className='text-grey'>Alpha</strong> - Bug fixing, reassessing, refactoring code</li>
            <li className='mb-1'><strong className='text-grey'>Beta</strong> - Bug fixing, reassessing, refactoring code</li>
            <li className='mb-1'><strong className='text-grey'>Delta</strong> - Hidden & TBD. Blockchain.</li>
            <li className='mb-1'><strong className='text-grey'>Lima</strong> - Hidden & TBD</li>
            <li className='mb-1'><strong className='text-grey'>Gamma</strong> - Hidden & TBD. Mobile App: iOS & Android</li>
            <li className='mb-1'><strong className='text-grey'>Galactic</strong> - Achieved a product people want. Monetization.</li>
            <li className='mb-1'><strong className='text-grey'>Universal</strong> - Begin growth phase, start getting more users.</li>
            <li className='mb-1'><strong className='text-grey'>- Maintenance</strong> - Bug fixing, reassessing, refactoring code.</li>
            <li className='mb-1'><strong className='text-grey'>- Enhancements</strong> - User requested enhancements</li>
          </ul>
        </div>
      ),
    },
    {
      label: 'FAQ',
      icon: faQuestionCircle,
      content: 'There are currently no questions that have been asked. Check back soon.',
    },
    {
      label: 'Get Help',
      icon: faHeadset,
      content: (
        <div className="get-help-box">
          <p className='mt-5'><FontAwesomeIcon icon={faPhone} size='lg' /> Call/Text directly for immediate help:
            <strong> +1 410-870-5476</strong></p>
          <p className='mt-5'><FontAwesomeIcon icon={faTicket} size='lg' /> Enter a ticket below or email <strong>helpdesk@bluith.com</strong> for a 24-48 hour response.</p>
          <p className='mt-5'><FontAwesomeIcon icon={faCalendarAlt} size='lg' /> Join the monthly AMA for all other inquiries. 10/18/2024 @ 7pm UTC</p>
          <p>Meeting Agenda: What problems are you having?</p>
          <p>To join the video meeting, click this link: <a href='https://meet.google.com/ffw-szza-mze'>https://meet.google.com/ffw-szza-mze</a>
            Otherwise, to join by phone, dial +1 505-663-6877 and enter this PIN: 897 022 239#
            To view more phone numbers, click this link: <a href='https://tel.meet/ffw-szza-mze?hs=5'>https://tel.meet/ffw-szza-mze?hs=5</a></p>
          <div className="d-flex get-help-ticket mt-5">
            <input type="text" placeholder="How may we help you?" value={message} onChange={(e) => setMessage(e.target.value)} />
            <button onClick={handleSubmit}>Submit</button>
          </div>
          {/* Show tickets when they are loaded */}
          <div className="tickets-section mb-5">
            {status === 'loading' && <p>Loading tickets...</p>}
            {status !== 'loading' && tickets?.length > 0 ? (
              <>
                {tickets.map((ticket) => (
                  <p key={ticket.id}>{`${ticket.message} - ${timeAgo(ticket.createdAt?.seconds)}`}</p>
                ))}
              </>
            ) : (
              status !== 'loading' && <p>No tickets found.</p>
            )}
          </div>
        </div>
      ),
    },
  ];

  // Find the content of the currently selected section
  const [selectedSection, setSelectedSection] = useState(sections[0].label);
  const selectedContent = sections.find(section => section.label === selectedSection)?.content;
  const selectedIcon = sections.find(section => section.label === selectedSection)?.icon;

  return (
    <div className="manual__container p-0 mx-md-auto">
      {/* Sidebar */}
      <nav className="manual__sidebar">
        <ul>
          {sections.map((section, index) =>
            section.separator ? (
              <hr key={`separator-${index}`} className="manual__separator" />
            ) : (
              <li
                key={section.label}
                onClick={() => setSelectedSection(section.label)}
                className={selectedSection === section.label ? 'active' : ''}
              >
                <FontAwesomeIcon icon={section.icon} />
                <span className="label-text">{section.label}</span>
              </li>
            )
          )}
        </ul>
      </nav>

      {/* Content Area */}
      <main className="manual__content">
        <div className='d-flex'>
          <FontAwesomeIcon icon={selectedIcon} size='3x' />
          <h1 className='ms-3 mt-2 mt-md-0'>{selectedSection}</h1>
        </div>
        <p>{selectedContent}</p>
      </main>
    </div>
  );
}

export default ManualPage